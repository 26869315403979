import { isReportSavedState } from '@src/client/components/common-report-components/state';
import {
  breakdownPropertyAttributionState,
  breakdownsState,
  validBreakdownsState,
} from '@src/client/components/filters-and-selectors/breakdown-selector/state';
import {
  approximateValueState,
  compareState,
  customCompareState,
} from '@src/client/components/filters-and-selectors/compare-selector/atoms';
import {
  conversionTimeWindowState,
  conversionWindowFunctionState,
} from '@src/client/components/filters-and-selectors/conversion-criteria-filter/state';
import {
  customDateRangeState,
  dateRangeState,
} from '@src/client/components/filters-and-selectors/date-range-selector/state';
import {
  FunnelAggregateByPropType,
  funnelAggregateByState,
  funnelStepsState,
  getEmptyFunnelStepsState,
  isCompareEnabledInAnyStepSelector,
  validFunnelStepsState,
} from '@src/client/components/filters-and-selectors/funnel-steps/state';
import { funnelTrendStepState } from '@src/client/components/filters-and-selectors/funnel-trend-step-selector/state';
import { funnelTrendTypeState } from '@src/client/components/filters-and-selectors/funnel-trend-type-selector/state';
import { funnelTypeState } from '@src/client/components/filters-and-selectors/funnel-type-selector/state';
import {
  globalFiltersStateV2,
  validGlobalFiltersState,
} from '@src/client/components/filters-and-selectors/global-property-filter/state';
import { granularityState } from '@src/client/components/filters-and-selectors/granularity-selector';
import { viewModeState } from '@src/client/components/view-mode-selector';
import { addQueryParamsToUrl } from '@src/client/helpers/reports/api';
import {
  DateRangeEnum,
  FunnelCountTypes,
  FunnelMeasureTypes,
  FunnelType,
  INITIAL_POLLING_INTERVAL,
  MAX_FUNNEL_STEP_GROUP,
  MAX_GRAPH_COLORS,
  PropertyAttribution,
} from '@src/client/helpers/reports/constants';
import { replacePastFlagFromCompareString } from '@src/client/helpers/reports/dataUtils';
import { FunnelDataVizRowAndChart, FunnelQueryBuilder, FunnelStep } from '@src/client/helpers/reports/types';
import { useNavigationLinkWithWorkspace, usePrevious } from '@src/client/hooks';
import { ErrorTags, EventNames, EventProperty } from '@src/client/lib/analytics/events';
import { ReportPerformanceMarkers, trackReportPerf } from '@src/client/lib/analytics/perfTracker';
import Tracker from '@src/client/lib/analytics/tracker';
import { createReport, updateReport } from '@src/client/lib/api/mutations/common';
import { getReport, getReportRunData, getRunByRunId } from '@src/client/lib/api/queries/common';
import { UpdateReportRequest } from '@src/client/lib/api/types/request';
import { PluralSupportedTimeUnits, ReportType } from '@src/client/lib/api/types/response';
import { isLengthyArray } from '@src/client/lib/utils';
import { SelectOptionsType } from '@src/client/ui-library/select';
import { debounce, isEqual } from 'lodash-es';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { isPrivateDimensionError } from '../../components/private-dimension-error';
import { useReportWorker } from '../../helpers/workers/hooks';
import {
  FunnelWorkerAction,
  FunnelWorkerMessageData,
  FunnelWorkerResponse,
  ReportWrokerType,
} from '../../helpers/workers/types';
import { useToast } from '../../ui-library/toast/use-toast';
import {
  checkFunnelStepsContainsStep,
  getBuilderDataFromFunnelResponse,
  getCreateFunnelObjectFromForm,
  insertAggregatesInRowFunnels,
  isFunnelStepValid,
  shouldRefetchFunnelOnParamsChange,
} from './dataUtils';
import {
  funnelAggregateTimeOption,
  funnelChartDataState,
  funnelDescriptionState,
  funnelNameState,
  funnelPrivateDimensionErrorState,
  funnelRawApiResponseState,
  funnelReportRowState,
  funnelRowsSelectionState,
  funnelsErrorState,
  funnelsRunIdState,
  funnelTableDataPageIndexState,
  isFunnelAnyOrderState,
  isFunnelsLoadingState,
} from './state';
import { FunnelAggregateAverageTimeOption, FunnelAggregateTimeOptions } from './types';

let FUNNEL_TRACE_ID: number;

const getPercentileFromOption = (timeOption: SelectOptionsType) =>
  !isEqual(timeOption, FunnelAggregateAverageTimeOption) ? Number(timeOption.value) : undefined;

const useSetAllFunnelsAtomValues = () => {
  const setFunnelSteps = useSetRecoilState(funnelStepsState);
  const setBreakdownsState = useSetRecoilState(breakdownsState);
  const setGlobalFiltersState = useSetRecoilState(globalFiltersStateV2);
  const setDateRangeState = useSetRecoilState(dateRangeState);
  const setFunnelName = useSetRecoilState(funnelNameState);
  const setFunnelDescription = useSetRecoilState(funnelDescriptionState);
  const setCustomDateRange = useSetRecoilState(customDateRangeState);
  const setCompareState = useSetRecoilState(compareState);
  const setCustomCompareState = useSetRecoilState(customCompareState);
  const setFunnelConversionWindow = useSetRecoilState(conversionTimeWindowState);
  const setFunnelConversionFunction = useSetRecoilState(conversionWindowFunctionState);
  const setFunnelType = useSetRecoilState(funnelTypeState);
  const setFunnelTrendType = useSetRecoilState(funnelTrendTypeState);
  const setFunnelTrendStep = useSetRecoilState(funnelTrendStepState);
  const setViewMode = useSetRecoilState(viewModeState);
  const setGranularityState = useSetRecoilState(granularityState);
  const setPropertyAttributionStatte = useSetRecoilState(breakdownPropertyAttributionState);
  const setIsFunnelAnyOrderState = useSetRecoilState(isFunnelAnyOrderState);
  const setAggregateTimeOption = useSetRecoilState(funnelAggregateTimeOption);
  const setFunnelAggragteByProp = useSetRecoilState(funnelAggregateByState);
  const setApproximateValueState = useSetRecoilState(approximateValueState);

  return (data: FunnelQueryBuilder) => {
    setFunnelSteps(data.steps || getEmptyFunnelStepsState());
    setBreakdownsState(data.breakdowns || []);
    setGlobalFiltersState(data['global-filters'] || []);
    setDateRangeState(data.dateRange);
    setFunnelName(data.funnelTitle);
    setFunnelDescription(data.funnelDescription);
    setCompareState(data.compare || []);
    setCustomCompareState(data.customCompareData);
    setFunnelConversionWindow(
      data.measure_time_window || {
        number: 10,
        unit: PluralSupportedTimeUnits.DAYS,
      },
    );
    setFunnelConversionFunction(data.function || FunnelCountTypes.UNIQUES);
    setApproximateValueState(data.approximateValue);
    setFunnelType(data.funnelType || FunnelType.STEPS);
    setIsFunnelAnyOrderState(data.isFunnelAnyOrder);
    setPropertyAttributionStatte(data.propertyAttribution || PropertyAttribution.LAST_TOUCH);
    if (data.funnelTrendType) {
      setFunnelTrendType(data.funnelTrendType);
    }
    if (data.funnelTrendStep) {
      setFunnelTrendStep(data.funnelTrendStep);
    }
    if (data.viewMode) {
      setViewMode(data.viewMode);
    }
    if (data.granularity) {
      setGranularityState(data.granularity);
    }

    if (data.dateRange === DateRangeEnum.CUSTOM && isLengthyArray(data.customDateRange)) {
      setCustomDateRange(data.customDateRange!);
    }
    if (data.dateRange === DateRangeEnum.SINCE && isLengthyArray(data.sinceDateRange)) {
      setCustomDateRange(data.sinceDateRange!);
    }
    setFunnelAggragteByProp(data.aggregating_by ?? FunnelAggregateByPropType.USER_ID);
    if (data.percentile) {
      const option = FunnelAggregateTimeOptions.find((f) => f.value === data.percentile!.toString());
      setAggregateTimeOption(option ?? FunnelAggregateAverageTimeOption);
    }
  };
};

const useGetAllFunnelAtomValues = (): FunnelQueryBuilder => {
  const steps = useRecoilValue(funnelStepsState);
  const breakdowns = useRecoilValue(validBreakdownsState);
  const globalFilters = useRecoilValue(validGlobalFiltersState);
  const granularity = useRecoilValue(granularityState);
  const dateRange = useRecoilValue(dateRangeState);
  const compare = useRecoilValue(compareState);
  const customCompareData = useRecoilValue(customCompareState);
  const funnelTitle = useRecoilValue(funnelNameState);
  const funnelDescription = useRecoilValue(funnelDescriptionState);
  const funnelConversionFunction = useRecoilValue(conversionWindowFunctionState);
  const funnelConversionWindow = useRecoilValue(conversionTimeWindowState);
  const funnelType = useRecoilValue(funnelTypeState);
  const funnelTrendType = useRecoilValue(funnelTrendTypeState);
  const funnelTrendStep = useRecoilValue(funnelTrendStepState);
  const viewMode = useRecoilValue(viewModeState);
  const propertyAttribution = useRecoilValue(breakdownPropertyAttributionState);
  const customDateRange = useRecoilValue(customDateRangeState);
  const isFunnelAnyOrder = useRecoilValue(isFunnelAnyOrderState);
  const aggregatingBy = useRecoilValue(funnelAggregateByState);
  const aggregateTimeOption = useRecoilValue(funnelAggregateTimeOption);
  const approximateValue = useRecoilValue(approximateValueState);

  return {
    funnelTitle,
    funnelDescription,
    steps,
    breakdowns,
    'global-filters': globalFilters,
    granularity,
    dateRange,
    viewMode,
    compare,
    function: funnelConversionFunction,
    measure_time_window: funnelConversionWindow,
    measured_as: FunnelMeasureTypes.CONVERSION,
    funnelType,
    funnelTrendType,
    funnelTrendStep,
    propertyAttribution,
    customCompareData,
    customDateRange,
    isFunnelAnyOrder,
    aggregating_by: aggregatingBy,
    percentile: getPercentileFromOption(aggregateTimeOption),
    approximateValue,
  };
};

export const useGetQueryBuilderDataForFunnel = (): FunnelQueryBuilder => {
  const steps = useRecoilValue(validFunnelStepsState);
  const breakdowns = useRecoilValue(validBreakdownsState);
  const globalFilters = useRecoilValue(validGlobalFiltersState);
  const dateRange = useRecoilValue(dateRangeState);
  const funnelTitle = useRecoilValue(funnelNameState);
  const funnelDescription = useRecoilValue(funnelDescriptionState);
  const customDateRange = useRecoilValue(customDateRangeState);
  const compare = useRecoilValue(compareState);
  const customCompareData = useRecoilValue(customCompareState);
  const funnelConversionFunction = useRecoilValue(conversionWindowFunctionState);
  const funnelConversionWindow = useRecoilValue(conversionTimeWindowState);
  const granularity = useRecoilValue(granularityState);
  const funnelType = useRecoilValue(funnelTypeState);
  const funnelTrendType = useRecoilValue(funnelTrendTypeState);
  const funnelTrendStep = useRecoilValue(funnelTrendStepState);
  const viewMode = useRecoilValue(viewModeState);
  const isFunnelAnyOrder = useRecoilValue(isFunnelAnyOrderState);
  const aggregateTimeOption = useRecoilValue(funnelAggregateTimeOption);
  const propertyAttribution = useRecoilValue(breakdownPropertyAttributionState);
  const aggregatingBy = useRecoilValue(funnelAggregateByState);
  const approximateValue = useRecoilValue(approximateValueState);

  return {
    funnelTitle,
    funnelDescription,
    steps: steps.map((dimension) => {
      if (!dimension.compare)
        return {
          ...dimension,
          step_label: checkFunnelStepsContainsStep(dimension.step_label, steps)
            ? `${dimension.alias} ${dimension.step_label}`
            : (dimension.step_label ?? ''),
        };
      return {
        ...dimension,
        compare: dimension.compare.map((compareStep) => ({
          ...compareStep,
          step_label: checkFunnelStepsContainsStep(compareStep.step_label, steps)
            ? `${compareStep.alias} ${compareStep.step_label}`
            : (compareStep.step_label ?? ''),
        })),
      };
    }),
    breakdowns,
    'global-filters': globalFilters,
    dateRange,
    viewMode,
    customDateRange,
    sinceDateRange: customDateRange,
    compare,
    customCompareData,
    function: funnelConversionFunction,
    measure_time_window: funnelConversionWindow,
    granularity,
    measured_as: FunnelMeasureTypes.CONVERSION,
    funnelType,
    funnelTrendType,
    funnelTrendStep,
    propertyAttribution,
    isFunnelAnyOrder,
    aggregating_by: aggregatingBy,
    percentile: getPercentileFromOption(aggregateTimeOption),
    approximateValue,
  };
};

export function removeAllCompareSteps(oldSteps: FunnelStep[]): FunnelStep[] {
  return oldSteps.map((step, stepIdx) =>
    step.compare?.length
      ? {
          ...step.compare[0],
          step_label: `${stepIdx + 1} ${step.compare[0].event}`,
          alias: (stepIdx + 1).toString(),
        }
      : step,
  );
}

export const useResetDerievedDataStatesOnParamChange = () => {
  const resetChartData = useResetRecoilState(funnelChartDataState);
  const resetTableRowsData = useResetRecoilState(funnelReportRowState);
  const resetErrorState = useResetRecoilState(funnelsErrorState);
  const resetRunId = useResetRecoilState(funnelsRunIdState);
  const resetFunnelSelectedRows = useResetRecoilState(funnelRowsSelectionState);
  const resetTableDataPageIndex = useResetRecoilState(funnelTableDataPageIndexState);
  const resetPvtDimnErrState = useResetRecoilState(funnelPrivateDimensionErrorState);
  const resetRawApiResponseState = useResetRecoilState(funnelRawApiResponseState);
  const [isFunnelAnyOrder] = useRecoilState(isFunnelAnyOrderState);
  const setFunnelSteps = useSetRecoilState(funnelStepsState);
  const resetPropertyAttributionState = useResetRecoilState(breakdownPropertyAttributionState);
  const resetFunnelConversionFunction = useResetRecoilState(conversionWindowFunctionState);
  const resetFunnelType = useResetRecoilState(funnelTypeState);
  const setFunnelConversionWindow = useSetRecoilState(conversionTimeWindowState);

  return () => {
    resetChartData();
    resetTableRowsData();
    resetErrorState();
    resetRunId();
    resetTableDataPageIndex();
    resetFunnelSelectedRows();
    resetPvtDimnErrState();
    resetRawApiResponseState();
    if (isFunnelAnyOrder) {
      resetFunnelType();
      resetFunnelConversionFunction();
      resetPropertyAttributionState();
      setFunnelSteps((prevSteps) => removeAllCompareSteps(prevSteps));
      setFunnelConversionWindow((prevWindow) =>
        prevWindow.unit === PluralSupportedTimeUnits.SESSIONS
          ? { number: 1, unit: PluralSupportedTimeUnits.DAYS }
          : prevWindow,
      );
    }
  };
};

export const useResetAllFunnelAtomValues = () => {
  const resetBreakdownsState = useResetRecoilState(breakdownsState);
  const resetGlobalFiltersState = useResetRecoilState(globalFiltersStateV2);
  const resetGranularityState = useResetRecoilState(granularityState);
  const resetDateRangeState = useResetRecoilState(dateRangeState);
  const resetCompareState = useResetRecoilState(compareState);
  const resetCustomCompareState = useResetRecoilState(customCompareState);
  const resetFunnelNameState = useResetRecoilState(funnelNameState);
  const resetFunnelDescriptionState = useResetRecoilState(funnelDescriptionState);
  const resetRunIdState = useResetRecoilState(funnelsRunIdState);
  const resetFunnelConversionWindow = useResetRecoilState(conversionTimeWindowState);
  const resetFunnelConversionFunction = useResetRecoilState(conversionWindowFunctionState);
  const resetFunnelSteps = useResetRecoilState(funnelStepsState);
  const resetFunnelType = useResetRecoilState(funnelTypeState);
  const resetFunnelTrendType = useResetRecoilState(funnelTrendTypeState);
  const resetFunnelTrendStep = useResetRecoilState(funnelTrendStepState);
  const resetViewMode = useResetRecoilState(viewModeState);
  const resetPropertyAttributionState = useResetRecoilState(breakdownPropertyAttributionState);
  const resetDerievedDataStates = useResetDerievedDataStatesOnParamChange();
  const resetFunnelPvtDimensionState = useResetRecoilState(funnelPrivateDimensionErrorState);
  const resetFunnelAnyOrderState = useResetRecoilState(isFunnelAnyOrderState);
  const resetFunnelAggregateTimeOption = useResetRecoilState(funnelAggregateTimeOption);
  const resetApproximateValueState = useResetRecoilState(approximateValueState);

  return () => {
    resetBreakdownsState();
    resetGlobalFiltersState();
    resetGranularityState();
    resetDateRangeState();
    resetCompareState();
    resetCustomCompareState();
    resetFunnelNameState();
    resetFunnelDescriptionState();
    resetRunIdState();
    resetFunnelConversionWindow();
    resetFunnelConversionFunction();
    resetFunnelSteps();
    resetFunnelType();
    resetFunnelTrendType();
    resetFunnelTrendStep();
    resetViewMode();
    resetPropertyAttributionState();
    resetDerievedDataStates();
    resetFunnelPvtDimensionState();
    resetFunnelAnyOrderState();
    resetFunnelAggregateTimeOption();
    resetApproximateValueState();
  };
};

export const useFunnelsConfigFetcher = (): {
  saveFunnel: (save: boolean) => void;
} => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { toast } = useToast();
  const { getLinkWithWorkspace } = useNavigationLinkWithWorkspace();
  const setAllAtomValues = useSetAllFunnelsAtomValues();
  const { itemExtId } = useParams<{ itemExtId: string }>();
  const queryParameters = new URLSearchParams(window?.location?.search);
  const [, setFunnelRunId] = useRecoilState(funnelsRunIdState);
  const setFunnelPrivateDimensionState = useSetRecoilState(funnelPrivateDimensionErrorState);
  const [configId, setConfigId] = useState<string | null>(queryParameters.get('configId'));
  const [isParamsChanged, setParamsChanged] = useState<boolean>(false);
  const [updateCount, setUpdateCount] = useState<number>(0);
  const initialRender = useRef(true);
  const builderData = useGetQueryBuilderDataForFunnel();
  const lastBuilderData = usePrevious(builderData);
  const setFunnelsError = useSetRecoilState(funnelsErrorState);
  const setIsFunnelsLoading = useSetRecoilState(isFunnelsLoadingState);
  const isNewFunnelsFlow = useRef(false);
  const resetDerievedDataValuesOnParamsChange = useResetDerievedDataStatesOnParamChange();
  const setIsReportSaved = useSetRecoilState(isReportSavedState);
  useEffect(() => {
    isNewFunnelsFlow.current = !itemExtId;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getFunnelConfig = useMutation(getReport, {
    retry: 1,
    onSuccess: (response) => {
      setConfigId(response.configId);
      setIsReportSaved(response.saved);
      setUpdateCount(updateCount + 1);
      setParamsChanged(false);
      if (response) {
        const data = getBuilderDataFromFunnelResponse(response);
        setAllAtomValues(data);
      } else {
        // NOTE: @manish handle empty config scenario
      }
    },
    onError: (error: Error, variables) => {
      if (isPrivateDimensionError(error)) {
        setFunnelPrivateDimensionState(error);
      } else {
        setFunnelsError(error);
        Tracker.trackError(error, ErrorTags.FUNNEL_CONFIG_FETCH_ERROR, {
          itemExtId: variables?.itemExtId,
          configId: variables?.configId,
        });
      }
      setIsFunnelsLoading(false);
    },
  });

  // create new funnel config entry
  const addFunnelRequest = useMutation(createReport, {
    onSuccess: (resp) => {
      setConfigId(resp.configId);
      // invalidate funnel fetch queries to refetch the data
      queryClient.invalidateQueries({ queryKey: ['getReportSummariesByUser'] });
      if (resp.runId) {
        setFunnelRunId(resp.runId);
        performance.mark(ReportPerformanceMarkers.ReportRunStart);
      }
      queryParameters.set('configId', resp.configId);
      navigate(getLinkWithWorkspace(addQueryParamsToUrl(`funnels/${resp.itemExternalId}`, queryParameters)), {
        replace: true,
      });
    },
    onError: (error: Error, variables) => {
      if (isPrivateDimensionError(error)) {
        setFunnelPrivateDimensionState(error);
      } else {
        setFunnelsError(error);
        Tracker.trackError(error, ErrorTags.FUNNEL_ADD_ERROR, {
          itemName: variables?.itemName,
          itemDescription: variables?.itemDescription,
          reportType: variables?.reportType,
        });
      }
      setIsFunnelsLoading(false);
    },
  });

  // update funnel config and fetch relevant run id
  const updateFunnelRequest = useMutation((payload: UpdateReportRequest) => updateReport(payload), {
    onSuccess: (resp, variables) => {
      if (resp.traceId === FUNNEL_TRACE_ID) {
        queryParameters.set('configId', resp.configId);
        navigate(getLinkWithWorkspace(addQueryParamsToUrl(`funnels/${resp.itemExternalId}`, queryParameters)));
        setConfigId(resp.configId);
        if (!variables.isSaved) setFunnelRunId(resp.runId);
        else {
          setIsFunnelsLoading(false);
        }
      }
    },
    onError: (error: Error, variables) => {
      if (isPrivateDimensionError(error)) {
        setFunnelPrivateDimensionState(error);
      } else {
        setFunnelsError(error);
        Tracker.trackError(error, ErrorTags.FUNNEL_UPDATE_ERROR, {
          configId: variables?.configId,
          itemId: variables?.itemId,
          itemExternalId: variables?.itemExternalId,
          reportType: variables?.reportType,
        });
      }
      setIsFunnelsLoading(false);
      // TODO @manish: figure out what all properties we need to send instead of passing entire variables list as that might be huge
    },
  });

  // set loading state effect
  useEffect(() => {
    if (getFunnelConfig.isLoading || updateFunnelRequest.isLoading || addFunnelRequest.isLoading) {
      setIsFunnelsLoading(true);
      setFunnelsError(undefined);
    } // NOTE: not setting loading to false in else as it will show a flash of dimension selection illustration. loading is set to false in data fetcher hook
  }, [getFunnelConfig.isLoading, updateFunnelRequest.isLoading, addFunnelRequest.isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  // fetch config if extid is present in url and existing report has been loaded
  // if a new report flow is triggered then isNewInsightFlow.current value will be true
  // check the first empty useEffect
  useEffect(() => {
    if (itemExtId && itemExtId !== '' && !isNewFunnelsFlow.current) {
      performance.mark(ReportPerformanceMarkers.ReportRunStart);
      getFunnelConfig.mutate({ itemExtId, configId });
    }
  }, [itemExtId]); // eslint-disable-line react-hooks/exhaustive-deps

  const saveFunnel = async (save = false) => {
    if (save && (builderData.funnelTitle === '' || !builderData.funnelTitle)) {
      toast({ variant: 'danger', title: '', description: 'Please add a report title' });
      return;
    }
    if (!isFunnelStepValid(builderData.steps)) {
      if (!save) return;
      toast({ variant: 'danger', title: 'Invalid query', description: 'Please add at least 2 steps' });
      return;
    }

    const createFunnelObject = getCreateFunnelObjectFromForm(builderData);
    createFunnelObject.reportType = ReportType.FUNNEL;

    if (itemExtId && itemExtId !== '') {
      FUNNEL_TRACE_ID = new Date().getTime();
      updateFunnelRequest.mutate({
        configId: configId!,
        itemExternalId: itemExtId,
        itemName: createFunnelObject.itemName,
        params: createFunnelObject.params,
        itemId: '',
        itemDescription: createFunnelObject.itemDescription,
        itemScope: createFunnelObject.itemScope,
        isConfigModified: true,
        reportType: ReportType.FUNNEL,
        isSaved: save,
        traceId: FUNNEL_TRACE_ID,
      });
    } else {
      addFunnelRequest.mutate({ ...createFunnelObject, isSaved: save });
    }
    setParamsChanged(false);
    if (updateCount !== 1) setIsReportSaved(save);
  };

  const deouncedRunFunnelsonParamChange = debounce(() => {
    performance.mark(ReportPerformanceMarkers.ReportRunStart);
    resetDerievedDataValuesOnParamsChange();
    setUpdateCount(updateCount + 1);
    saveFunnel();
  }, 200);

  useEffect(() => {
    if (initialRender.current) {
      return;
    }
    if (isParamsChanged) {
      if (updateCount === 0 && itemExtId) {
        setUpdateCount(updateCount + 1);
      } else {
        deouncedRunFunnelsonParamChange();
      }
      setParamsChanged(false);
    }
  }, [isParamsChanged]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // if (lastBuilderData !== undefined && initialRender.current) {
    //   initialRender.current = false;
    //   return;
    // }
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    const shouldFetchReport = shouldRefetchFunnelOnParamsChange(builderData, lastBuilderData);
    if (shouldFetchReport) {
      setParamsChanged(true);
    }
  }, [builderData, lastBuilderData]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    saveFunnel,
  };
};

export const useFunnelsDataFetcherWithWorker = () => {
  const funnelRunId = useRecoilValue(funnelsRunIdState);
  const funnelType = useRecoilValue(funnelTypeState);
  const funnelTrendType = useRecoilValue(funnelTrendTypeState);
  const setRows = useSetRecoilState(funnelReportRowState);
  const setChartData = useSetRecoilState(funnelChartDataState);
  const setSelectedRowKeys = useSetRecoilState(funnelRowsSelectionState);
  const setIsFunnelsLoading = useSetRecoilState(isFunnelsLoadingState);
  const setFunnelsError = useSetRecoilState(funnelsErrorState);
  const [runDataIsLoading, setRunDataIsLoading] = useState<boolean>(false);
  const [runDataError, setRunDataError] = useState<Error>();
  const [refetchInterval, setRefetchInterval] = useState<number | false | undefined>(INITIAL_POLLING_INTERVAL);
  const { toast } = useToast();
  const reportInputs = useGetAllFunnelAtomValues();
  const isEventCompareEnabled = useRecoilValue(isCompareEnabledInAnyStepSelector);
  const computeWorkerReqId = useRef<string>();
  const setRawApiResponse = useSetRecoilState(funnelRawApiResponseState);
  const terminationTimer = useRef<number | undefined>();
  // const increaseRefetchInterval = useRef<number | undefined>();

  const setFormattedDataForDisplay = useCallback(
    (formattedDataTemp: FunnelDataVizRowAndChart) => {
      const { breakdowns, compare, steps, 'global-filters': globalFilters } = reportInputs;
      const formattedData =
        funnelType === FunnelType.TRENDS
          ? {
              chartData: formattedDataTemp.chartData,
              rows: insertAggregatesInRowFunnels(formattedDataTemp.rows, compare, funnelTrendType),
            }
          : formattedDataTemp;
      if (formattedData.rows.length > 0) {
        const defaultSelectedKeys: React.Key[] = [];
        if (funnelType === FunnelType.TRENDS) {
          if (compare && compare.length === 2) {
            formattedData.rows.slice(0, MAX_GRAPH_COLORS / 2).forEach((r: any) => {
              defaultSelectedKeys.push(r.key);
              defaultSelectedKeys.push(replacePastFlagFromCompareString(r.key));
            });
          } else {
            formattedData.rows.slice(0, MAX_GRAPH_COLORS).forEach((r: any) => {
              defaultSelectedKeys.push(r.key);
            });
          }
        }
        if (funnelType === FunnelType.STEPS) {
          formattedData.rows.slice(0, MAX_FUNNEL_STEP_GROUP).forEach((r: any) => {
            defaultSelectedKeys.push(r.key);
          });
        }
        setRows(formattedData.rows);
        setChartData(formattedData.chartData);
        setSelectedRowKeys(defaultSelectedKeys);
        performance.mark(ReportPerformanceMarkers.ReportClientRunEnd);
        trackReportPerf(ReportType.FUNNEL, funnelRunId, {
          breakdownsCount: breakdowns?.length,
          stepsCount: steps?.length,
          filtersCount: globalFilters?.length,
          isCompareEnabled: compare && compare.length === 2,
          isEventCompareEnabled,
        });
      }
    },
    [reportInputs, funnelRunId, funnelType, funnelTrendType, isEventCompareEnabled], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleWorkerResponse = useCallback(
    (e: MessageEvent<string>) => {
      const response = JSON.parse(e.data) as FunnelWorkerResponse;
      if (response.success && response.result) {
        if (response.reqId !== computeWorkerReqId?.current) {
          Tracker.trackEvent(EventNames.WRONG_REQUEST_DATA_RECIEVED_FROM_WROKER, {
            [EventProperty.ReportType]: ReportType.FUNNEL,
          });
          console.warn('[PI]: Outdated res received from worker. Waiting for lastest data');
          return;
        }
        const formattedData = response.result;
        setFormattedDataForDisplay(formattedData);
        setIsFunnelsLoading(false);
      } else {
        setRunDataError(new Error('Something went wrong while rendering the data. Please try again after sometime'));
        setIsFunnelsLoading(false);
      }
    },
    [setFormattedDataForDisplay], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const { computeWorker } = useReportWorker(ReportWrokerType.FUNNEL_WORKER, handleWorkerResponse);

  const runStatusResponse = useQuery([funnelRunId!], () => getRunByRunId(funnelRunId), {
    enabled: funnelRunId != null,
    refetchIntervalInBackground: false,
    refetchInterval,
    refetchOnWindowFocus: false,
  });

  const callWorkerForDataFormatting = (runData: Record<string, any>) => {
    setRawApiResponse(runData);
    if (runData && Object.keys(runData).length > 0) {
      const { breakdowns, steps, compare, granularity, dateRange, customDateRange, customCompareData } = reportInputs;
      computeWorkerReqId.current = Date.now().toString();

      computeWorker?.postMessage(
        JSON.stringify({
          reqId: computeWorkerReqId.current,
          actionType: FunnelWorkerAction.generateTableRowsAndChartData,
          actionData: {
            breakdowns: breakdowns || [],
            steps,
            data: runData,
            funnelType,
            funnelTrendType,
            compare,
            granularity,
            timestampData: {
              dateRange,
              customDateRange,
            },
            customCompareData,
          },
        } as FunnelWorkerMessageData),
      );
    } else {
      toast({ variant: 'danger', title: 'Failed to load response data' });
      Tracker.trackEvent(EventNames.EMPTY_SERVER_RESPONSE_ERROR, {
        [EventProperty.ReportType]: ReportType.FUNNEL,
        [EventProperty.RunId]: funnelRunId,
        [EventProperty.ReportUrl]: runStatusResponse.data?.outputPath,
      });
      setIsFunnelsLoading(false);
      performance.mark(ReportPerformanceMarkers.ReportClientRunEnd);
      trackReportPerf(ReportType.FUNNEL, funnelRunId);
    }
  };

  const getFunnelRunDataRequest = useMutation(getReportRunData, {
    onSuccess: (response) => {
      performance.mark(ReportPerformanceMarkers.ReportServerRunEnd);
      callWorkerForDataFormatting(response);
    },
    onError: (error: Error, variable) => {
      setRunDataIsLoading(false);
      toast({ variant: 'danger', title: 'Error loading Funnel results', description: error.toString() });
      Tracker.trackError(error, ErrorTags.FUNNEL_FETCH_RUN_DATA_ERROR, {
        reportPath: variable,
      });
    },
  });

  // set loading state effect
  useEffect(() => {
    if (runDataIsLoading || getFunnelRunDataRequest.isLoading) {
      setIsFunnelsLoading(true);
      setFunnelsError(undefined);
      setRunDataError(undefined);
    } else {
      setIsFunnelsLoading(false);
    }
  }, [runDataIsLoading, getFunnelRunDataRequest.isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  // set error state effect
  useEffect(() => {
    if (getFunnelRunDataRequest.error || runStatusResponse.error) {
      setFunnelsError(getFunnelRunDataRequest.error || runStatusResponse.error);
      setIsFunnelsLoading(false);
    } else {
      setFunnelsError(undefined);
    }
  }, [getFunnelRunDataRequest.error, runStatusResponse.error]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (funnelRunId != null) {
      setRunDataIsLoading(true);
      setRefetchInterval(INITIAL_POLLING_INTERVAL);
    }
  }, [funnelRunId]);

  useEffect(() => {
    if (runStatusResponse.data?.status === 'FINISHED') {
      setRefetchInterval(false);
      setRunDataIsLoading(false);
      if (terminationTimer.current) clearTimeout(terminationTimer.current);
      if (funnelRunId != null && runStatusResponse.data.outputPath != null) {
        getFunnelRunDataRequest.mutate(runStatusResponse.data.outputPath);
      }
    }
    if (runStatusResponse.data && runStatusResponse.data?.status === 'ERRORED') {
      setRefetchInterval(false);
      toast({
        variant: 'danger',
        title: 'Unable to fetch Funnel',
        description: 'Something went wrong during query execution. Please try after some time',
      });
      setRunDataIsLoading(false);
      setIsFunnelsLoading(false);
      setRunDataError(new Error('Funnel query run errored', { cause: runStatusResponse.data.errorMsg }));
    }
    if (
      (runStatusResponse.data?.status === 'INITIATED' || runStatusResponse.data?.status === 'QUERY_BUILT') &&
      !terminationTimer.current
    ) {
      // double the polling duration every 5sec
      // increaseRefetchInterval.current = window.setInterval(() => {
      //   setRefetchInterval((prevRefetchInterval) =>
      //     typeof prevRefetchInterval === 'number' ? prevRefetchInterval * 2 : INITIAL_POLLING_INTERVAL,
      //   );
      // }, 5000);

      // stop the polling after 60sec
      terminationTimer.current = window.setTimeout(() => {
        setRefetchInterval(false);
        setRunDataIsLoading(false);
        toast({
          variant: 'danger',
          title: 'Unable to fetch Funnel',
          description: 'Query execution terminated due to internal error. Please try after some time.',
        });
      }, 60000);
    }

    return () => {
      clearTimeout(terminationTimer.current);
      // clearInterval(increaseRefetchInterval.current);
    };
  }, [runStatusResponse.data]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    loading: runDataIsLoading || getFunnelRunDataRequest.isLoading,
    error: getFunnelRunDataRequest.error || runStatusResponse.error,
    runDataError,
  };
};
