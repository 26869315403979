import {
  breakdownsState,
  validBreakdownsState,
} from '@src/client/components/filters-and-selectors/breakdown-selector/state';
import { chartTypeState } from '@src/client/components/filters-and-selectors/chart-type-selector';
import {
  approximateValueState,
  compareState,
  customCompareState,
} from '@src/client/components/filters-and-selectors/compare-selector/atoms';
import {
  customDateRangeState,
  dateRangeState,
} from '@src/client/components/filters-and-selectors/date-range-selector/state';
import { dimensionsStateV2 } from '@src/client/components/filters-and-selectors/dimension-filter/state';
import { formulasState } from '@src/client/components/filters-and-selectors/forrmula-creator/state';
import {
  globalFiltersStateV2,
  validGlobalFiltersState,
} from '@src/client/components/filters-and-selectors/global-property-filter/state';
import { granularityState } from '@src/client/components/filters-and-selectors/granularity-selector';
import { viewModeState } from '@src/client/components/view-mode-selector';
import { addQueryParamsToUrl } from '@src/client/helpers/reports/api';
import {
  ChartType,
  DateRangeEnum,
  INITIAL_POLLING_INTERVAL,
  MAX_GRAPH_COLORS,
} from '@src/client/helpers/reports/constants';
import { replacePastFlagFromCompareString } from '@src/client/helpers/reports/dataUtils';
import { useNewInsightComputeFns } from '@src/client/helpers/reports/hooks';
import { Breakdown, DataVizRowAndChart, InsightQueryBuilder } from '@src/client/helpers/reports/types';
import { insertAverageTotalInRow, sortRowsByValueColumn } from '@src/client/helpers/reports/uiHelpers';
import { useNavigationLinkWithWorkspace, usePrevious } from '@src/client/hooks';
import { ErrorTags, EventNames, EventProperty } from '@src/client/lib/analytics/events';
import { ReportPerformanceMarkers, trackReportPerf } from '@src/client/lib/analytics/perfTracker';
import Tracker from '@src/client/lib/analytics/tracker';
import { createReport, updateReport } from '@src/client/lib/api/mutations/common';
import { getAlertCountForReport, getReport, getReportRunData, getRunByRunId } from '@src/client/lib/api/queries/common';
import { UpdateReportRequest } from '@src/client/lib/api/types/request';
import { DimensionType, InsightRunDataResI, ReportType } from '@src/client/lib/api/types/response';
import { isLengthyArray } from '@src/client/lib/utils';
import { useToast } from '@src/client/ui-library/toast/use-toast';
import { debounce } from 'lodash-es';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { isPrivateDimensionError } from '../../components/private-dimension-error';
import { alertDialogOpenState, reportSaveConfirmationDialogOpenState } from '../../helpers/reports/atoms';
import { useReportWorker } from '../../helpers/workers/hooks';
import {
  InsightWorkerAction,
  InsightWorkerMessageData,
  InsightWorkerResponse,
  ReportWrokerType,
} from '../../helpers/workers/types';
import {
  areSelectedRowsDirtyState,
  insightBreakdownValuesMapState,
  insightChartDataState,
  insightDescriptionState,
  insightNameState,
  insightPrivateDimensionErrorState,
  insightReportRowState,
  insightRowsSelectionState,
  insightsErrorState,
  insightsRawApiResponseState,
  insightsRunIdState,
  insightTableDataPageIndexState,
  isInsightSavedState,
  isInsightsLoadingState,
} from './state';
import {
  getBuilderDataFromInsightResponse,
  getCreateInsightObjectFromBuilderData,
  shouldRefetchInsightOnParamsChange,
} from './utils';

let INSIGHT_TRACE_ID: number;

export const useResetDerievedDataStatesOnParamChange = () => {
  const resetChartData = useResetRecoilState(insightChartDataState);
  const resetErrorState = useResetRecoilState(insightsErrorState);
  const resetTableRowsData = useResetRecoilState(insightReportRowState);
  const resetRunId = useResetRecoilState(insightsRunIdState);
  const resetTableDataPageIndex = useResetRecoilState(insightTableDataPageIndexState);
  const resetPvtDimnErrState = useResetRecoilState(insightPrivateDimensionErrorState);
  const resetRawApiRes = useResetRecoilState(insightsRawApiResponseState);

  return () => {
    resetChartData();
    resetErrorState();
    resetRunId();
    resetTableRowsData();
    resetTableDataPageIndex();
    resetPvtDimnErrState();
    resetRawApiRes();
  };
};

const sortTableData = (breakdowns: Breakdown[]) =>
  !breakdowns.some((breakdown) => breakdown.type === DimensionType.EVENT);

export const useSetAllInsightAtomValues = () => {
  const setReportName = useSetRecoilState(insightNameState);
  const setReportDescription = useSetRecoilState(insightDescriptionState);
  const setDimensionState = useSetRecoilState(dimensionsStateV2);
  const setBreakdownsState = useSetRecoilState(breakdownsState);
  const setGlobalFiltersState = useSetRecoilState(globalFiltersStateV2);
  const setFormulasState = useSetRecoilState(formulasState);
  const setGranularityState = useSetRecoilState(granularityState);
  const setChartTypeState = useSetRecoilState(chartTypeState);
  const setDateRangeState = useSetRecoilState(dateRangeState);
  const setCompareState = useSetRecoilState(compareState);
  const setCustomCompareDateState = useSetRecoilState(customCompareState);
  const setCustomDateRange = useSetRecoilState(customDateRangeState);
  const setViewModeState = useSetRecoilState(viewModeState);
  const setApproximateValueState = useSetRecoilState(approximateValueState);

  return (data: InsightQueryBuilder) => {
    setReportName(data.insightTitle);
    setReportDescription(data.insightDescription);
    setDimensionState(data.dimensions);
    setBreakdownsState(data.breakdowns || []);
    setGlobalFiltersState(data['global-filters'] || []);
    setFormulasState(data.formulas || []);
    setGranularityState(data.granularity);
    setChartTypeState(data.chartType);
    setViewModeState(data.viewMode);
    setDateRangeState(data.dateRange);
    setCompareState(data.compare || []);
    setApproximateValueState(data.approximateValue);
    setCustomCompareDateState(data.customCompareData);
    if (data.dateRange === DateRangeEnum.CUSTOM && isLengthyArray(data.customDateRange)) {
      setCustomDateRange(data.customDateRange!);
    }
    if (data.dateRange === DateRangeEnum.SINCE && isLengthyArray(data.sinceDateRange)) {
      setCustomDateRange(data.sinceDateRange!);
    }
  };
};

export const useGetAllInsightAtomValues = (): InsightQueryBuilder => {
  const dimensions = useRecoilValue(dimensionsStateV2);
  const breakdowns = useRecoilValue(validBreakdownsState);
  const globalFilters = useRecoilValue(validGlobalFiltersState);
  const formulas = useRecoilValue(formulasState);
  const granularity = useRecoilValue(granularityState);
  const chartType = useRecoilValue(chartTypeState);
  const dateRange = useRecoilValue(dateRangeState);
  const compare = useRecoilValue(compareState);
  const customCompareData = useRecoilValue(customCompareState);
  const insightTitle = useRecoilValue(insightNameState);
  const insightDescription = useRecoilValue(insightDescriptionState);
  const viewMode = useRecoilValue(viewModeState);
  const customDateRange = useRecoilValue(customDateRangeState);
  const approximateValue = useRecoilValue(approximateValueState);
  return {
    insightTitle,
    insightDescription,
    dimensions,
    breakdowns,
    'global-filters': globalFilters,
    formulas,
    granularity,
    chartType,
    dateRange,
    viewMode,
    compare,
    customCompareData,
    customDateRange,
    approximateValue,
  };
};

export const useGetQueryBuilderDataForInsight = (): InsightQueryBuilder => {
  const dimensions = useRecoilValue(dimensionsStateV2);
  const breakdowns = useRecoilValue(validBreakdownsState);
  const globalFilters = useRecoilValue(validGlobalFiltersState);
  const formulas = useRecoilValue(formulasState);
  const granularity = useRecoilValue(granularityState);
  const chartType = useRecoilValue(chartTypeState);
  const viewMode = useRecoilValue(viewModeState);
  const dateRange = useRecoilValue(dateRangeState);
  const insightTitle = useRecoilValue(insightNameState);
  const insightDescription = useRecoilValue(insightDescriptionState);
  const customDateRange = useRecoilValue(customDateRangeState);
  const compare = useRecoilValue(compareState);
  const customCompareData = useRecoilValue(customCompareState);
  const approximateValue = useRecoilValue(approximateValueState);

  return {
    insightTitle,
    insightDescription,
    dimensions,
    breakdowns,
    'global-filters': globalFilters,
    formulas,
    granularity,
    chartType,
    dateRange,
    viewMode,
    customDateRange,
    sinceDateRange: customDateRange,
    compare,
    customCompareData,
    approximateValue,
  };
};

export const useResetAllInsightsAtomValues = () => {
  const resetDimensionState = useResetRecoilState(dimensionsStateV2);
  const resetBreakdowns = useResetRecoilState(breakdownsState);
  const resetGlobalFiltersState = useResetRecoilState(globalFiltersStateV2);
  const resetFormulasState = useResetRecoilState(formulasState);
  const resetGranularityState = useResetRecoilState(granularityState);
  const resetChartTypeState = useResetRecoilState(chartTypeState);
  const resetDateRangeState = useResetRecoilState(dateRangeState);
  const resetCustomDateRange = useResetRecoilState(customDateRangeState);
  const resetCompareState = useResetRecoilState(compareState);
  const resetCustomCompareDateState = useResetRecoilState(customCompareState);
  const resetInsightsName = useResetRecoilState(insightNameState);
  const resetInsightDescription = useResetRecoilState(insightDescriptionState);
  const resetViewMode = useResetRecoilState(viewModeState);
  const resetInsightConfigLoadingState = useResetRecoilState(isInsightsLoadingState);
  const resetPvtDimnErrState = useResetRecoilState(insightPrivateDimensionErrorState);
  const resetInsightSelectedRows = useResetRecoilState(insightRowsSelectionState);
  const resetAreSelectedRowsDirty = useResetRecoilState(areSelectedRowsDirtyState);
  const resetInsightBreakdownValuesMap = useResetRecoilState(insightBreakdownValuesMapState);
  const resetDerievedDataStatesOnParamChange = useResetDerievedDataStatesOnParamChange();
  const resetApproximateValueState = useResetRecoilState(approximateValueState);

  return () => {
    resetInsightConfigLoadingState();
    resetDimensionState();
    resetBreakdowns();
    resetGlobalFiltersState();
    resetFormulasState();
    resetGranularityState();
    resetChartTypeState();
    resetDateRangeState();
    resetCustomDateRange();
    resetCompareState();
    resetCustomCompareDateState();
    resetInsightsName();
    resetInsightDescription();
    resetViewMode();
    resetDerievedDataStatesOnParamChange();
    resetAreSelectedRowsDirty();
    resetInsightSelectedRows();
    resetPvtDimnErrState();
    resetInsightBreakdownValuesMap();
    resetApproximateValueState();
  };
};

export const useInsightConfigFetcher = (): {
  saveInsight: (save: boolean) => void;
  saveInsightAsCopy: () => void;
  activeAlertCount: number;
  isReportSaved: boolean;
  isParamsChanged: boolean;
  itemExternalId?: string;
} => {
  const navigate = useNavigate();
  const { getLinkWithWorkspace } = useNavigationLinkWithWorkspace();
  const setAllAtomValues = useSetAllInsightAtomValues();
  const { itemExtId } = useParams<{ itemExtId: string }>();
  const queryParameters = new URLSearchParams(window?.location?.search);
  const [configId, setConfigId] = useState<string | null>(queryParameters.get('configId'));
  const [isReportSaved, setIsReportSaved] = useRecoilState(isInsightSavedState);
  const [, setInsightRunId] = useRecoilState(insightsRunIdState);
  const queryClient = useQueryClient();
  const { toast } = useToast();
  const builderData = useGetQueryBuilderDataForInsight();
  const lastBuilderData = usePrevious(builderData);
  const [isParamsChanged, setParamsChanged] = useState<boolean>(false);
  const [updateCount, setUpdateCount] = useState<number>(0);
  const [alertCount, setAlertCount] = useState<number>(0);
  const alertDialogOpen = useRecoilValue(alertDialogOpenState);
  const setIsInsightsLoading = useSetRecoilState(isInsightsLoadingState);
  const setPvtDimnErrState = useSetRecoilState(insightPrivateDimensionErrorState);
  const setInsightsError = useSetRecoilState(insightsErrorState);
  const [, setReportSaveConfirmationDialogOpen] = useRecoilState(reportSaveConfirmationDialogOpenState);
  const resetDerievedDataValuesOnParamsChange = useResetDerievedDataStatesOnParamChange();
  const isNewInsightFlow = useRef(false);
  useEffect(() => {
    isNewInsightFlow.current = !itemExtId;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getInsightConfig = useMutation(getReport, {
    retry: 1,
    onSuccess: (response) => {
      setConfigId(response.configId);
      setIsReportSaved(response.saved);
      setUpdateCount(updateCount + 1);
      if (response) {
        const data = getBuilderDataFromInsightResponse(response);
        setAllAtomValues(data);
      } else {
        // NOTE: @manish handle empty config scenario
      }
    },
    onError: (error: Error, variables) => {
      if (isPrivateDimensionError(error)) {
        setPvtDimnErrState(error);
      } else {
        setInsightsError(error);
        Tracker.trackError(error, ErrorTags.INSIGHT_CONFIG_FETCH_ERROR, {
          itemExtId: variables?.itemExtId,
          configId: variables?.configId,
        });
      }
      setIsInsightsLoading(false);
    },
  });

  const getAlertCountForReportRequest = useQuery(
    ['getAlertCountForReport', itemExtId],
    () => getAlertCountForReport({ itemExtId: itemExtId || '' }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        setAlertCount(response);
      },
      onError: (err: Error) => {
        Tracker.trackError(err, ErrorTags.ALERT_CONFIG_FETCH_ERROR);
      },
      enabled: isReportSaved,
    },
  );

  // update insight config and fetch relevant run id
  const updateInsightRequest = useMutation((payload: UpdateReportRequest) => updateReport(payload), {
    onSuccess: (resp, variables) => {
      if (resp.traceId === INSIGHT_TRACE_ID) {
        queryParameters.set('configId', resp.configId);
        navigate(getLinkWithWorkspace(addQueryParamsToUrl(`insights/${resp.itemExternalId}`, queryParameters)), {
          replace: true,
        });
        setConfigId(resp.configId);
        if (!variables.isSaved) setInsightRunId(resp.runId);
        else {
          setIsInsightsLoading(false);
        }
      }
    },
    onError: (error: Error, variables) => {
      setInsightsError(error);
      setIsInsightsLoading(false);
      Tracker.trackError(error, ErrorTags.INSIGHT_UPDATE_ERROR, {
        configId: variables?.configId,
        itemId: variables?.itemId,
        itemExternalId: variables?.itemExternalId,
        reportType: variables?.reportType,
      });
    },
  });

  // create new inisght config entry
  const addInsight = useMutation(createReport, {
    onSuccess: (resp) => {
      setConfigId(resp.configId);
      // invalidate insight fetch queries to refetch the data
      queryClient.invalidateQueries({ queryKey: ['getReportSummariesByUser'] });
      if (resp.runId) {
        setInsightRunId(resp.runId);
        performance.mark(ReportPerformanceMarkers.ReportRunStart);
      }
      queryParameters.set('configId', resp.configId);
      navigate(getLinkWithWorkspace(addQueryParamsToUrl(`insights/${resp.itemExternalId}`, queryParameters)), {
        replace: true,
      });
      setReportSaveConfirmationDialogOpen(false);
    },
    onError: (error: Error, variables) => {
      setInsightsError(error);
      setIsInsightsLoading(false);
      Tracker.trackError(error, ErrorTags.INSIGHT_ADD_ERROR, {
        itemName: variables?.itemName,
        itemDescription: variables?.itemDescription,
        reportType: variables?.reportType,
      });
    },
  });

  // set loading state effect
  useEffect(() => {
    if (getInsightConfig.isLoading || updateInsightRequest.isLoading || addInsight.isLoading) {
      setIsInsightsLoading(true);
      setInsightsError(undefined);
    } // NOTE: not setting loading to false in else as it will show a flash of dimension selection illustration. loading is set to false in data fetcher hook
  }, [getInsightConfig.isLoading, updateInsightRequest.isLoading, addInsight.isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const saveInsight = async (save = false) => {
    if (save && (builderData.insightTitle === '' || !builderData.insightTitle)) {
      toast({ variant: 'danger', title: '', description: 'Please add a report title' });
      return;
    }
    if (!isLengthyArray(builderData.dimensions)) {
      if (!save) return;
      toast({ variant: 'danger', title: 'Invalid query', description: 'Please select at least 1 dimension' });
      return;
    }

    const createInsightObject: any = getCreateInsightObjectFromBuilderData(builderData);

    createInsightObject.reportType = ReportType.INSIGHT;
    if (itemExtId && itemExtId !== '') {
      INSIGHT_TRACE_ID = new Date().getTime();
      updateInsightRequest.mutate({
        configId: configId!,
        itemExternalId: itemExtId,
        itemName: createInsightObject.itemName,
        params: createInsightObject.params,
        itemId: '',
        itemDescription: createInsightObject.itemDescription,
        itemScope: createInsightObject.itemScope,
        isConfigModified: true, // save ?? isConfigChanged,
        reportType: ReportType.INSIGHT,
        isSaved: save,
        traceId: INSIGHT_TRACE_ID,
      });
    } else {
      addInsight.mutate({ ...createInsightObject, isSaved: save });
    }
    setParamsChanged(false);
    if (updateCount !== 1) setIsReportSaved(save);
  };

  const saveInsightAsCopy = async (save = true) => {
    if (save && (builderData.insightTitle === '' || !builderData.insightTitle)) {
      toast({ variant: 'danger', title: '', description: 'Please add a report title' });
      return;
    }
    if (!isLengthyArray(builderData.dimensions)) {
      if (!save) return;
      toast({ variant: 'danger', title: 'Invalid query', description: 'Please select at least 1 dimension' });
      return;
    }

    const createInsightObject: any = getCreateInsightObjectFromBuilderData(builderData);

    createInsightObject.reportType = ReportType.INSIGHT;
    addInsight.mutate({
      ...createInsightObject,
      itemName: `[Duplicate] ${createInsightObject.itemName}`,
      isSaved: save,
    });
    setParamsChanged(false);
    if (updateCount !== 1) setIsReportSaved(save);
  };

  const debouncedRunInsighsonParamChange = debounce(() => {
    performance.mark(ReportPerformanceMarkers.ReportRunStart);
    resetDerievedDataValuesOnParamsChange();
    saveInsight();
    setUpdateCount(updateCount + 1);
  }, 300);

  useEffect(() => {
    getAlertCountForReportRequest.refetch();
  }, [alertDialogOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isParamsChanged) {
      if (updateCount === 0 && itemExtId) {
        setUpdateCount(updateCount + 1);
      } else {
        debouncedRunInsighsonParamChange();
      }
      setParamsChanged(false);
    }
  }, [isParamsChanged]); // eslint-disable-line react-hooks/exhaustive-deps

  // fetch config if extid is present in url and existing report has been loaded
  // if a new report flow is triggered then isNewInsightFlow.current value will be true
  // check the first empty useEffect
  useEffect(() => {
    if (itemExtId && itemExtId !== '' && !isNewInsightFlow.current) {
      performance.mark(ReportPerformanceMarkers.ReportRunStart);
      getInsightConfig.mutate({ itemExtId, configId });
    }
  }, [itemExtId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const shouldFetchReport = shouldRefetchInsightOnParamsChange(builderData, lastBuilderData);
    if (shouldFetchReport) {
      setParamsChanged(true);
    }
  }, [builderData]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    saveInsight,
    saveInsightAsCopy,
    itemExternalId: itemExtId,
    activeAlertCount: alertCount,
    isParamsChanged,
    isReportSaved,
  };
};

export const useInsightDataFetcherWithWorker = () => {
  const insightRunId = useRecoilValue(insightsRunIdState);
  const [runDataIsLoading, setRunDataIsLoading] = useState<boolean>(false);
  const [refetchInterval, setRefetchInterval] = useState<number | false | undefined>(INITIAL_POLLING_INTERVAL);
  const { toast } = useToast();
  const reportInputs = useGetAllInsightAtomValues();
  const setRows = useSetRecoilState(insightReportRowState);
  const [areSelectedRowsDirty, setAreSelectedRowsDirty] = useRecoilState(areSelectedRowsDirtyState);
  const setChartData = useSetRecoilState(insightChartDataState);
  const [selectedRowKeys, setSelectedRowKeys] = useRecoilState(insightRowsSelectionState);
  const [runDataError, setRunDataError] = useState<Error>();
  const setIsInsightsLoading = useSetRecoilState(isInsightsLoadingState);
  const setInsightsError = useSetRecoilState(insightsErrorState);
  const computeWorkerReqId = useRef<string>();
  const setInsightsRawData = useSetRecoilState(insightsRawApiResponseState);
  const terminationTimer = useRef<number | undefined>();
  const shouldUseNewComputeInsightFns = useNewInsightComputeFns();
  const setInsightBreakdownValuesMap = useSetRecoilState(insightBreakdownValuesMapState);
  // const increaseRefetchInterval = useRef<number | undefined>();

  const setFormattedDataForDisplay = useCallback(
    (formattedDataTemp: DataVizRowAndChart) => {
      const { breakdowns, formulas, compare, chartType, dimensions, 'global-filters': globalFilters } = reportInputs;
      const formattedData: DataVizRowAndChart =
        chartType === ChartType.LINE
          ? {
              chartData: formattedDataTemp.chartData,
              rows: insertAverageTotalInRow(formattedDataTemp.rows, compare, false, sortTableData(breakdowns ?? [])),
              breakdownValuesMap: formattedDataTemp.breakdownValuesMap,
            }
          : {
              chartData: formattedDataTemp.chartData,
              rows: sortTableData(breakdowns ?? [])
                ? sortRowsByValueColumn(formattedDataTemp.rows, 'desc')
                : formattedDataTemp.rows,
              breakdownValuesMap: formattedDataTemp.breakdownValuesMap,
            };
      setChartData(formattedData.chartData);
      if (formattedData.breakdownValuesMap) {
        setInsightBreakdownValuesMap(formattedData.breakdownValuesMap);
      }
      if (formattedData.rows.length > 0) {
        const defaultSelectedKeys: React.Key[] = [];
        if (compare && compare.length === 2 && chartType !== ChartType.PIE && chartType !== ChartType.LINE) {
          formattedData.rows.slice(0, MAX_GRAPH_COLORS / 2).forEach((r: any) => {
            defaultSelectedKeys.push(r.key);
            defaultSelectedKeys.push(replacePastFlagFromCompareString(r.key));
          });
        } else {
          formattedData.rows.slice(0, MAX_GRAPH_COLORS).forEach((r: any) => {
            defaultSelectedKeys.push(r.key);
          });
        }
        setRows(formattedData.rows);
        if (areSelectedRowsDirty && selectedRowKeys.some((key) => defaultSelectedKeys.includes(key))) {
          setSelectedRowKeys(defaultSelectedKeys.filter((key) => selectedRowKeys.includes(key)));
        } else {
          if (areSelectedRowsDirty) setAreSelectedRowsDirty(false);
          setSelectedRowKeys(defaultSelectedKeys);
        }

        performance.mark(ReportPerformanceMarkers.ReportClientRunEnd);
        trackReportPerf(ReportType.INSIGHT, insightRunId, {
          breakdownsCount: breakdowns?.length,
          dimensionsCount: dimensions?.length,
          formulasCount: formulas?.length,
          filtersCount: globalFilters?.length,
          isCompareEnabled: compare && compare.length === 2,
          chartType,
        });
      }
    },
    [reportInputs, insightRunId], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleWorkerResponse = useCallback(
    (e: MessageEvent<string>) => {
      const response = JSON.parse(e.data) as InsightWorkerResponse;
      if (response.success && response.result) {
        if (response.reqId !== computeWorkerReqId?.current) {
          Tracker.trackEvent(EventNames.WRONG_REQUEST_DATA_RECIEVED_FROM_WROKER, {
            [EventProperty.ReportType]: ReportType.INSIGHT,
          });
          console.warn('[PI]: Outdated res received from worker. Waiting for lastest data');
          return;
        }
        setFormattedDataForDisplay(response.result);
        setIsInsightsLoading(false);
      } else {
        setRunDataError(new Error('Something went wrong while rendering the data. Please try again after sometime'));
        setIsInsightsLoading(false);
      }
    },
    [setFormattedDataForDisplay], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const { computeWorker } = useReportWorker(ReportWrokerType.INSIGHT_WORKER, handleWorkerResponse);

  const runStatusResponse = useQuery([insightRunId!], () => getRunByRunId(insightRunId), {
    enabled: insightRunId != null && insightRunId !== '' && insightRunId !== undefined,
    refetchIntervalInBackground: false,
    refetchInterval,
    refetchOnWindowFocus: false,
  });

  const callWorkerForDataFormatting = (serverResponse: InsightRunDataResI) => {
    if (serverResponse && Object.keys(serverResponse).length > 0) {
      const {
        breakdowns,
        formulas,
        granularity: groupedBy,
        compare,
        chartType,
        customCompareData,
        dateRange,
        customDateRange,
      } = reportInputs;
      computeWorkerReqId.current = Date.now().toString();
      computeWorker?.postMessage(
        JSON.stringify({
          reqId: computeWorkerReqId.current,
          actionType: InsightWorkerAction.generateTableRowsAndChartData,
          shouldUseNewComputeInsightFns,
          actionData: {
            breakdowns: breakdowns || [],
            formulas,
            data: serverResponse,
            compare,
            chartType,
            granularity: groupedBy,
            customCompareData,
            timestampData: {
              dateRange,
              customDateRange,
            },
          },
        } as InsightWorkerMessageData),
      );
      setInsightsRawData(serverResponse);
    } else {
      toast({ variant: 'danger', title: 'Failed to load response data' });
      Tracker.trackEvent(EventNames.EMPTY_SERVER_RESPONSE_ERROR, {
        [EventProperty.ReportType]: ReportType.INSIGHT,
        [EventProperty.RunId]: insightRunId,
        [EventProperty.ReportUrl]: runStatusResponse.data?.outputPath,
      });
      setIsInsightsLoading(false);
      performance.mark(ReportPerformanceMarkers.ReportClientRunEnd);
      const { breakdowns, formulas, compare, chartType, dimensions, 'global-filters': globalFilters } = reportInputs;
      trackReportPerf(ReportType.INSIGHT, insightRunId, {
        breakdownsCount: breakdowns?.length,
        dimensionsCount: dimensions?.length,
        formulasCount: formulas?.length,
        filtersCount: globalFilters?.length,
        isCompareEnabled: compare && compare.length === 2,
        chartType,
      });
    }
  };

  const getInsightRunDataRequest = useMutation(getReportRunData, {
    onSuccess: (response) => {
      performance.mark(ReportPerformanceMarkers.ReportServerRunEnd);
      callWorkerForDataFormatting(response);
      setRunDataIsLoading(false);
    },
    onError: (error: Error, variable) => {
      toast({ variant: 'danger', title: 'Error loading Insight results', description: error.toString() });
      Tracker.trackError(error, ErrorTags.INSIGHT_FETCH_RUN_DATA_ERROR, {
        reportPath: variable,
      });
    },
  });

  // set loading state effect
  useEffect(() => {
    if (runDataIsLoading || getInsightRunDataRequest.isLoading) {
      setIsInsightsLoading(true);
      setInsightsError(undefined);
      setRunDataError(undefined);
    }
  }, [runDataIsLoading, getInsightRunDataRequest.isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  // set error state effect
  useEffect(() => {
    if (getInsightRunDataRequest.error || runStatusResponse.error) {
      setInsightsError(getInsightRunDataRequest.error || runStatusResponse.error);
      setIsInsightsLoading(false);
    } else {
      setInsightsError(undefined);
    }
  }, [getInsightRunDataRequest.error, runStatusResponse.error]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (insightRunId != null) {
      setRunDataIsLoading(true);
      setRefetchInterval(INITIAL_POLLING_INTERVAL);
    }
  }, [insightRunId]);

  useEffect(() => {
    if (runStatusResponse.data?.status === 'FINISHED') {
      setRefetchInterval(false);
      setRunDataIsLoading(false);
      if (terminationTimer.current) clearTimeout(terminationTimer.current);
      if (insightRunId != null && runStatusResponse.data.outputPath != null) {
        getInsightRunDataRequest.mutate(runStatusResponse.data.outputPath);
      }
    }
    if (runStatusResponse.data && runStatusResponse.data?.status === 'ERRORED') {
      setRefetchInterval(false);
      toast({
        variant: 'danger',
        title: 'Unable to fetch Insight',
        description: 'Something went wrong during query execution. Please try after some time',
      });
      setRunDataIsLoading(false);
      setIsInsightsLoading(false);
      setRunDataError(new Error('Insight query run errored', { cause: runStatusResponse.data.errorMsg }));
    }
    if (
      (runStatusResponse.data?.status === 'INITIATED' || runStatusResponse.data?.status === 'QUERY_BUILT') &&
      !terminationTimer.current
    ) {
      // increase the polling duration every 5sec
      // increaseRefetchInterval.current = window.setInterval(() => {
      //   setRefetchInterval((prevRefetchInterval) =>
      //     typeof prevRefetchInterval === 'number'
      //       ? prevRefetchInterval + prevRefetchInterval / 2
      //       : INITIAL_POLLING_INTERVAL,
      //   );
      // }, 5000);

      // stop the polling after 60sec
      terminationTimer.current = window.setTimeout(() => {
        setRefetchInterval(false);
        setRunDataIsLoading(false);
        toast({
          variant: 'danger',
          title: 'Unable to fetch Insight',
          description: 'Query execution terminated due to internal error. Please try after some time.',
        });
      }, 60000);
    }

    return () => {
      clearTimeout(terminationTimer.current);
      // clearInterval(increaseRefetchInterval.current);
    };
  }, [runStatusResponse.data]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    loading: runDataIsLoading || getInsightRunDataRequest.isLoading,
    error: getInsightRunDataRequest.error || runStatusResponse.error,
    runDataError,
  };
};
