import { ViewMode } from '@src/client/helpers/reports/constants';
import { capitalize, convertToLabelValue } from '@src/client/lib/utils';
import { Select, SelectOptionsType } from '@src/client/ui-library/select';
import { useEffect } from 'react';
import { atom, useRecoilState, useResetRecoilState } from 'recoil';

export const VIEW_MODE_OPTIONS = [ViewMode.LINEAR, ViewMode.LOGARITHMIC, ViewMode.CUMULATIVE];

const getViewModeDefinition = (viewModeValue: ViewMode): string => {
  if (viewModeValue === ViewMode.LINEAR) {
    return 'Standard for counting over time';
  }
  if (viewModeValue === ViewMode.CUMULATIVE) {
    return "Accumulates each period's value";
  }
  if (viewModeValue === ViewMode.LOGARITHMIC) {
    return 'Ideal for wide value ranges';
  }
  return 'Please make a selection';
};

const VIEW_MODES = VIEW_MODE_OPTIONS.map((vm) => ({
  label: capitalize(vm),
  value: vm,
  tooltip: getViewModeDefinition(vm),
}));

export const viewModeState = atom<ViewMode>({
  key: 'viewModeStateKey',
  default: ViewMode.LINEAR,
});

export default function ViewModeSelector() {
  const [viewMode, setViewMode] = useRecoilState(viewModeState);
  const resetViewMode = useResetRecoilState(viewModeState);

  useEffect(
    () => () => {
      resetViewMode();
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Select
      className="min-w-[120px]"
      placeholder="View Mode"
      value={convertToLabelValue(viewMode)}
      options={VIEW_MODES}
      onChange={(value) => setViewMode((value as SelectOptionsType).value as ViewMode)}
      selectClassNames={{ control: 'rounded w-[120px]', singleValue: 'font-semibold capitalize text-xs' }}
    />
  );
}
