import { CustomCompareStateType } from '@src/client/components/filters-and-selectors/compare-selector/atoms';
import { FunnelAggregateByPropType } from '@src/client/components/filters-and-selectors/funnel-steps/state';
import { QueryBuilderTimestampInfo } from '@src/client/helpers/workers/types';
import { DimensionType, ReportItemResponse } from '@src/client/lib/api/types/response';
import {
  flattenArrayOfObjects,
  formatPercent,
  formatTime,
  getDiffPropertiesBetweenTwoObjects,
  getTypeOfFilterPropertyValues,
  hasValidValues,
  isLengthyArray,
  roundNumbersInObject,
} from '@src/client/lib/utils';
import dayjs from 'dayjs';
import { isEqual } from 'lodash-es';

import { hasBreakdownValueChanged } from '../../components/filters-and-selectors/breakdown-selector/utils';
import { hasFunnelStepsValueChanged } from '../../components/filters-and-selectors/funnel-steps/stepUtils';
import {
  getValidFilters,
  hasGlobalFiltersChanged,
} from '../../components/filters-and-selectors/global-property-filter/utils';
import {
  DateRangeEnum,
  FunnelMeasureTypes,
  FunnelTrendType,
  FunnelType,
  GranularityEnum,
  PropertyAttribution,
} from '../../helpers/reports/constants';
import {
  addLineChartData,
  addPastFlagToCompareString,
  compareStepName,
  covertFunnelEventsCompareCombinations,
  getBreakDownStrArray,
  getCompareFormObjFromReportResponse,
  getCustomDateRangeFormObjReportResponse,
  getFilterFormObjFromReportResponse,
  getFilterMapping,
  getFormattedCompare,
  getFormattedGlobalFilters,
  getFormattedGroupBy,
  getFormattedTimestampProps,
  getFormObjectForGroupBy,
  getFunnelStepGraphItemColor,
  getGraphItemColor,
  getSinceDateRangeFormObjReportResponse,
  isBreakdownApplied,
} from '../../helpers/reports/dataUtils';
import {
  Breakdown,
  DataVizRow,
  FunnelBarGraphData,
  FunnelCompareStep,
  FunnelDataVizRow,
  FunnelDataVizRowAndChart,
  FunnelQueryBuilder,
  FunnelStep,
  LineChartData,
} from '../../helpers/reports/types';
import {
  insertAverageInRowWithPercentage,
  insertAverageTotalInRow,
  insertAverageTotalInRowWithTime,
} from '../../helpers/reports/uiHelpers';
import { mergeChartDataForLineChart, mergeRowsForLineChart } from '../insights/utils';

export function isFunnelStepValid(steps: FunnelStep[]): boolean {
  if (steps.length < 2) return false;

  // eslint-disable-next-line consistent-return
  steps.forEach((step: FunnelStep, stepIdx: number) => {
    if (step.compare) {
      if (step.compare.length < 2) return false;
      // eslint-disable-next-line consistent-return
      step.compare.forEach((compareStep: FunnelCompareStep) => {
        if (!compareStep.event || compareStep.event === '') return false;
      });
    } else if (stepIdx < 2) {
      if (!step.event || step.event === '') return false;
    }
  });

  return true;
}

export const shouldRefetchFunnelOnParamsChange = (
  builderData: FunnelQueryBuilder,
  lastBuilderData: FunnelQueryBuilder | undefined,
): boolean => {
  const changedFields = getDiffPropertiesBetweenTwoObjects(builderData, lastBuilderData || {}) as Array<
    keyof FunnelQueryBuilder
  >;
  const funnelFieldsNotAffectingData = ['funnelTitle', 'funnelDescription'];
  if (
    !isLengthyArray(builderData.steps) ||
    isEqual(builderData, lastBuilderData) ||
    isEqual(changedFields, funnelFieldsNotAffectingData)
  ) {
    return false;
  }

  if (isLengthyArray(changedFields)) {
    const shouldFetchReport = changedFields.some((value) => {
      const changedData = builderData[value];
      if (value === 'steps') return hasFunnelStepsValueChanged(builderData.steps, lastBuilderData?.steps);
      if (value === 'breakdowns') return hasBreakdownValueChanged(builderData.breakdowns, lastBuilderData?.breakdowns);
      if (value === 'global-filters')
        return hasGlobalFiltersChanged(builderData['global-filters'], lastBuilderData?.['global-filters']);
      const isValid =
        !!value &&
        (!!changedData || (!changedData && lastBuilderData?.[value])) &&
        !funnelFieldsNotAffectingData.includes(value) &&
        (hasValidValues(changedData) || (!hasValidValues(changedData) && hasValidValues(lastBuilderData?.[value])));

      return isValid;
    });
    return shouldFetchReport;
  }

  return false;
};

export function checkFunnelStepHasEventCompare(step: FunnelStep): boolean {
  if (step.compare && Array.isArray(step.compare!) && step.compare!.length > 1) {
    return true;
  }
  return false;
}

export const checkFunnelStepsContainsStep = (
  eventName: string | undefined,
  steps: FunnelStep[],
  compareArray = false,
): boolean =>
  steps.filter((stepItem: FunnelStep) => {
    if (!stepItem.compare) {
      return stepItem.event === eventName;
    }
    return checkFunnelStepsContainsStep(eventName, stepItem.compare, true);
  }).length > (compareArray ? 0 : 1);

const getCompareDimensionFormObjFromFunnelResponse = (dimensions: any): FunnelCompareStep[] => {
  const formattedValues: FunnelCompareStep[] = [];
  dimensions.forEach((it: any) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { alias, step_label, event, filters, first_time_filter: ftf } = it;
    const obj: Partial<FunnelStep> = { alias, step_label, event, first_time_filter: ftf };
    if (filters && filters.config && filters.config.length > 0) {
      obj.filter = getFilterFormObjFromReportResponse(filters);
    }
    // obj.alias = numberToAlphabets(index);
    // obj.step_label = obj.step_label;
    formattedValues.push(obj as FunnelCompareStep);
  });
  return formattedValues;
};

const getFormattedObj = (item: any): any => {
  const obj: any = {};
  if (item.alias) obj.alias = item.alias;
  if (item.step_label) obj.step_label = item.step_label;
  // else obj.step_label = item.event;
  if (item.math) obj.math = item.math;
  if (item.event) obj.event = item.event;
  obj.event_type = item.event_type ?? DimensionType.EVENT;
  if (item['unique-on']) obj['unique-on'] = item['unique-on'];
  if (item.first_time_filter) obj.first_time_filter = item.first_time_filter;
  const filterList = getValidFilters(item.filter);
  if (filterList !== undefined && filterList.length > 0) {
    const filterObj: any = {};
    const filterConfig: any[] = [];
    filterList.forEach((f: any) => {
      const configObj: any = {};
      configObj.key = f.key;
      configObj.resource_type = 'event';
      configObj.property_name = f.property;
      configObj.operator = f.operator;
      configObj.property_values = f.values;
      configObj.property_value_dt = getTypeOfFilterPropertyValues(f.values);
      configObj.property_map = f.map;
      filterConfig.push(configObj);
    });
    const filterMappingStr = getFilterMapping(filterList);
    if (filterMappingStr !== '') filterObj.mapping = filterMappingStr;
    filterObj.config = filterConfig;
    obj.filters = filterObj;
  }
  return obj;
};

export const getFunnelFormattedSteps = (dimensions: any[]): any[] => {
  const formattedDimensions: any[] = [];
  dimensions.forEach((item: any) => {
    let obj: any = {};
    if (item.compare) {
      const compareArr: any[] = [];
      item.compare.forEach((compareItem: any) => {
        const compareObj = getFormattedObj(compareItem);
        compareArr.push(compareObj);
      });
      obj.compare = compareArr;
    } else {
      obj = getFormattedObj(item);
    }
    formattedDimensions.push(obj);
  });
  return formattedDimensions;
};

export const getCreateFunnelObjectFromForm = (builderData: FunnelQueryBuilder) => {
  const createFunnelObject: any = {};
  const params: any = {};
  const itemName = builderData.funnelTitle ?? 'Untitled Funnel';
  const itemDescription = builderData.funnelDescription;
  const groupBy = builderData.breakdowns;
  const { steps } = builderData;
  const globalFilters = builderData['global-filters'];
  const { dateRange } = builderData;
  const { customDateRange } = builderData;
  const { sinceDateRange } = builderData;
  const { compare, customCompareData } = builderData;
  const measuredAs: FunnelMeasureTypes = FunnelMeasureTypes.CONVERSION;
  const timeWindow = builderData.measure_time_window;
  const countType = builderData.function;
  const { granularity } = builderData;
  const { funnelType } = builderData;
  const { funnelTrendType } = builderData;
  const { funnelTrendStep } = builderData;
  const { viewMode } = builderData;
  const { propertyAttribution } = builderData;
  const { percentile } = builderData;
  const { approximateValue } = builderData;

  if (funnelType !== undefined) {
    params.funnel_type = funnelType;
  }
  if (funnelType && funnelType === FunnelType.TRENDS && funnelTrendType !== undefined) {
    params.funnel_trend_type = funnelTrendType;
  }
  if (funnelType && funnelType === FunnelType.TRENDS && funnelTrendStep !== undefined) {
    params.funnel_trend_step = funnelTrendStep;
  }
  if (funnelType && funnelType === FunnelType.TRENDS && viewMode !== undefined) {
    params.funnel_trend_view_mode = viewMode;
  }
  if (funnelType && funnelType === FunnelType.TRENDS && granularity !== undefined) {
    params.funnel_trend_granularity = granularity;
  }
  if (percentile !== undefined && funnelType === FunnelType.STEPS) {
    params.percentile = percentile;
  }

  params.any_order = builderData.isFunnelAnyOrder;
  params.aggregating_by = builderData.aggregating_by;
  params.approximate_value = approximateValue;

  if (measuredAs !== undefined) {
    params.measure = {
      measured_as: measuredAs,
      time_window: {
        value: timeWindow.number,
        unit: timeWindow.unit,
      },
      function: countType,
    };
  }

  if (groupBy !== undefined && groupBy.length > 0) {
    params.group_by = getFormattedGroupBy(groupBy);
  }
  if (steps !== undefined && steps.length > 0) {
    params.steps = getFunnelFormattedSteps(steps);
  }
  if (globalFilters !== undefined && globalFilters.length > 0) {
    params['global-filters'] = getFormattedGlobalFilters(globalFilters);
  }
  const timestampProps = getFormattedTimestampProps(granularity, dateRange, customDateRange, sinceDateRange);
  params.timestamp_props = timestampProps;
  params.additional_metrics = { metrics_list: ['average'] };

  if (compare !== undefined && compare.length > 0) {
    params.compare = getFormattedCompare(compare, customCompareData);
  }

  if (propertyAttribution !== undefined) {
    params.property_attribution = propertyAttribution;
  }

  createFunnelObject.itemName = itemName;
  createFunnelObject.itemDescription = itemDescription;
  createFunnelObject.itemScope = 'PUBLIC';
  createFunnelObject.params = params;

  return createFunnelObject;
};

const geFunnelStepsFromFunnelResponse = (dimensions: any): FunnelStep[] => {
  const formattedValues: FunnelStep[] = [];
  dimensions.forEach((it: any, index: number) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { alias, step_label, event, event_type, filters, first_time_filter: ftf, compare } = it;
    let obj: Partial<FunnelStep> = {};
    if (compare) {
      const compareArr: FunnelCompareStep[] = getCompareDimensionFormObjFromFunnelResponse(compare);
      obj.compare = compareArr;
    } else {
      obj = { alias, step_label, event, event_type, first_time_filter: ftf };
      if (filters && filters.config && filters.config.length > 0) {
        obj.filter = getFilterFormObjFromReportResponse(filters);
      }
      obj.alias = `${index + 1}`;
      if (obj.step_label === obj.event) {
        // doing this as step label in new ui has alias prefixed to event name for unique labels
        obj.step_label = `${index + 1} ${obj.event}`;
      }
    }
    formattedValues.push(obj as FunnelStep);
  });
  return formattedValues;
};

export const getBuilderDataFromFunnelResponse = (response: ReportItemResponse): FunnelQueryBuilder => {
  const globalFilters = response.params['global-filters'];
  const { steps } = response.params;
  const { compare } = response.params;
  const breakdowns = response.params.group_by;
  const funnelType = response.params.funnel_type ? response.params.funnel_type : FunnelType.STEPS; // for backward compatibility
  const propertyAttribution = response.params.property_attribution
    ? response.params.property_attribution
    : PropertyAttribution.LAST_TOUCH; // for backward compatibility
  const formvalues: Partial<FunnelQueryBuilder> = {
    funnelTitle: response.name ?? '',
    funnelDescription: response.description,
    dateRange: response.params.timestamp_props.date_range_type
      ? response.params.timestamp_props.date_range_type.toLowerCase(``)
      : ``,
    granularity: response.params.timestamp_props ? response.params.timestamp_props.granularity : null,
    funnelType,
    propertyAttribution,
    isFunnelAnyOrder: response.params.any_order,
    aggregating_by: response.params.aggregating_by ?? FunnelAggregateByPropType.USER_ID,
    percentile: response.params.percentile,
    approximateValue: response.params.approximate_value ?? true,
  };

  if (response.params.timestamp_props.date_range_type === DateRangeEnum.CUSTOM) {
    formvalues.customDateRange = getCustomDateRangeFormObjReportResponse(response.params.timestamp_props);
  }
  if (response.params.timestamp_props.date_range_type === DateRangeEnum.SINCE) {
    formvalues.sinceDateRange = getSinceDateRangeFormObjReportResponse(response.params.timestamp_props);
  }
  if (breakdowns && breakdowns.length > 0) {
    formvalues.breakdowns = getFormObjectForGroupBy(breakdowns);
  }
  if (response.params.measure) {
    formvalues.measured_as = response.params.measure.measured_as;
    formvalues.measure_time_window = {
      number: response.params.measure.time_window.value,
      unit: response.params.measure.time_window.unit,
    };
    formvalues.function = response.params.measure.function;
  }
  if (globalFilters && globalFilters.config && globalFilters.config.length > 0) {
    formvalues['global-filters'] = getFilterFormObjFromReportResponse(globalFilters);
  }
  if (steps && steps.length > 0) {
    formvalues.steps = geFunnelStepsFromFunnelResponse(steps);
  }
  if (compare) {
    const formattedCompareData = getCompareFormObjFromReportResponse(compare);
    formvalues.compare = formattedCompareData.compareData;
    formvalues.customCompareData = formattedCompareData.customCompareData;
  }
  if (funnelType === FunnelType.TRENDS && response.params.funnel_trend_type) {
    formvalues.funnelTrendType = response.params.funnel_trend_type;
  }
  if (funnelType === FunnelType.TRENDS && response.params.funnel_trend_step) {
    formvalues.funnelTrendStep = response.params.funnel_trend_step;
  }
  if (funnelType === FunnelType.TRENDS && response.params.funnel_trend_view_mode) {
    formvalues.viewMode = response.params.funnel_trend_view_mode;
  }
  if (funnelType === FunnelType.TRENDS && response.params.funnel_trend_granularity) {
    formvalues.granularity = response.params.funnel_trend_granularity;
  }
  return formvalues as FunnelQueryBuilder;
};

export function checkFunnelStepsHasEventCompare(steps: FunnelStep[]): boolean {
  return steps.some((funnelStep) => funnelStep.compare && funnelStep.compare.length > 0);
}

function findStepIndexesWithCompare(objects: FunnelStep[]): number[] {
  const indexes: number[] = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < objects.length; i++) {
    if (objects[i].compare && Array.isArray(objects[i].compare) && objects[i].compare!.length > 0) {
      indexes.push(i);
    }
  }

  return indexes;
}

const isValidDatetime = (datetimeStr: string) =>
  datetimeStr !== undefined && dayjs(datetimeStr).isValid() && datetimeStr.includes('T');
const stepCompareColumnName = (stepIndex: number): string => `step${stepIndex + 1}Comparison`;
const getNestedData = (data: any, path: string[]): any => {
  if (path.length > 0) {
    return getNestedData(data[path[0]], path.slice(1));
  }
  return data;
};
function getNestedPaths(obj: any): string[][] {
  const paths: string[][] = [];

  function exploreObject(currentObj: any, currentPath: string[] = []) {
    if (Array.isArray(currentObj)) {
      paths.push(currentPath);
      return;
    }

    if (typeof currentObj === 'object' && currentObj !== null) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key of Object.keys(currentObj)) {
        const newPath = currentPath.concat(key);
        exploreObject(currentObj[key], newPath);
      }
    }
  }

  exploreObject(obj);
  return paths;
}
const addPrefixToKeys = (prefix: string, obj: { [key: string]: any }): { [key: string]: any } => {
  const newObj: { [key: string]: any } = {};
  Object.keys(obj).forEach((key) => {
    newObj[prefix + key] = obj[key];
    newObj[key] = obj[key];
  });
  return newObj;
};
const replacePrefixToKeys = (
  oldPrefix: string,
  newPrefix: string,
  obj: { [key: string]: any },
): { [key: string]: any } => {
  const newObj: { [key: string]: any } = {};

  Object.keys(obj).forEach((key) => {
    if (key.startsWith(oldPrefix)) {
      const newKey = newPrefix + key.slice(oldPrefix.length);
      newObj[newKey] = obj[key];
    } else {
      newObj[key] = obj[key];
    }
  });

  return newObj;
};

const mergeOriginalAndCompareData = (
  originalData: FunnelDataVizRowAndChart,
  compareData: FunnelDataVizRowAndChart,
): FunnelDataVizRowAndChart => {
  const chartData = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < compareData?.chartData?.length; i++) {
    const originalBarChartData = originalData.chartData[i] as FunnelBarGraphData;
    const compareBarChartData = compareData.chartData[i] as FunnelBarGraphData;
    chartData.push({
      ...compareBarChartData,
      event: addPastFlagToCompareString(compareBarChartData.event, true),
      key: `${compareBarChartData.event}`,
    });
    chartData.push({ ...originalBarChartData });
  }
  const mergedData = {
    rows: [...originalData.rows, ...compareData.rows],
    chartData: [...chartData],
  };
  return mergedData;
};

const getBreakdownRows = (
  level: number,
  data: any,
  breakdowns: Breakdown[],
  breakdownPath: string[],
  rows: FunnelDataVizRow[],
): FunnelDataVizRow[] => {
  Object.keys(data).forEach((property) => {
    const currentRowsLength = rows.length;
    let breakdownVals = [...breakdownPath];
    breakdownVals.push(property);
    if (Array.isArray(data[property])) {
      const tempRows: { [key: string]: any }[] = [];
      data[property].forEach((dataRow: { [key: string]: any }) => {
        const obj = { ...dataRow };
        // obj.key = Math.round(Math.random() * 1000000);
        const row = addPrefixToKeys(obj.event, obj);
        breakdowns.forEach((brkdwn, idx) => {
          row[brkdwn.property as string] = breakdownVals[idx];
        });
        tempRows.push(roundNumbersInObject(row));
      });
      rows.push({
        ...(flattenArrayOfObjects(tempRows) as FunnelDataVizRow),
        events: tempRows.map((row) => row.event),
        totalConv:
          tempRows.length > 0
            ? tempRows[tempRows.length - 1][`${tempRows[tempRows.length - 1].event}percent_from_first_step`]
            : '',
        color: getGraphItemColor(currentRowsLength),
        key: `${Math.round(Math.random() * 1000000)}`,
        uniqueKey: `${Math.round(Math.random() * 1000000)}`,
        breakdown: breakdownVals.join('|'),
      });
    } else if (typeof data[property] === 'object' && data[property] != null) {
      getBreakdownRows(level + 1, data[property], breakdowns, breakdownVals, rows);
    }
    breakdownVals = [];
  });
  return rows;
};

const addChartDataForEventCompareWithoutBreakdown = (
  pathArr: string[],
  stepIndexesWithEventCompare: number[],
  steps: FunnelStep[],
  dataPoints: any,
  chartData: FunnelBarGraphData[],
  dataRowKey: string,
  dataRowColor: string,
) => {
  const statData = getNestedData(dataPoints, pathArr);
  Object.keys(statData).forEach((dataPoint) => {
    const eventName = stepIndexesWithEventCompare.includes(+dataPoint)
      ? compareStepName(+dataPoint + 1, steps[+dataPoint].compare!.length)
      : statData[dataPoint].event;
    chartData.push({
      ...roundNumbersInObject(statData[dataPoint]),
      event: eventName,
      step_index: String(+dataPoint + 1),
      color: dataRowColor,
      breakdown: pathArr.join(' / '),
      key: `${statData[dataPoint].event}-overall`,
      dataRowKey,
    } as FunnelBarGraphData);
  });
};

const addChartDataForEventCompareWithBreakdown = (
  breakdownPath: string[],
  pathArr: string[],
  stepIndexesWithEventCompare: number[],
  steps: FunnelStep[],
  dataPoints: any,
  chartData: FunnelBarGraphData[],
  dataRowColor: string,
  dataRowKey: string,
) => {
  const tillBrkdwnNesting = getNestedData(dataPoints, pathArr);
  const statData = getNestedData(tillBrkdwnNesting, breakdownPath);
  Object.keys(statData).forEach((dataPoint) => {
    const eventName = stepIndexesWithEventCompare.includes(+dataPoint)
      ? compareStepName(+dataPoint + 1, steps[+dataPoint].compare!.length)
      : statData[dataPoint].event;
    chartData.push({
      ...roundNumbersInObject(statData[dataPoint]),
      event: eventName,
      step_index: String(+dataPoint + 1),
      color: dataRowColor, // getGraphItemColor(0),
      breakdown: pathArr.join(' / ').concat(' / ').concat(breakdownPath.join(' / ')),
      key: `${statData[dataPoint].event}-overall`,
      dataRowKey,
    } as FunnelBarGraphData);
  });
};

const addChartData = (
  breakdownApplied: boolean,
  breakdownColums: string[],
  dataPoints: any,
  chartData: FunnelBarGraphData[],
  dataRowKey: string,
  dataRowColor: string,
  isPastData = false,
) => {
  if (Object.values(breakdownColums).length === 0 && !breakdownApplied) {
    Object.keys(dataPoints).forEach((dataPoint) => {
      chartData.push({
        ...roundNumbersInObject(dataPoints[dataPoint]),
        // step_index: dataPoint,
        step_index: dataPoints[dataPoint].event.split(' ')[0],
        color: dataRowColor,
        breakdown: addPastFlagToCompareString('Overall', isPastData),
        key: addPastFlagToCompareString(`${dataPoints[dataPoint].event}-Overall`, isPastData),
        dataRowKey,
      } as FunnelBarGraphData);
    });
  } else {
    chartData.push({
      ...roundNumbersInObject(dataPoints),
      breakdown: addPastFlagToCompareString(breakdownColums.join('|'), isPastData),
      key: addPastFlagToCompareString(`${dataPoints.event}-${breakdownColums.join('|')}`, isPastData),
      color: dataRowColor,
      dataRowKey,
      step_index: dataPoints.event.split(' ')[0],
    } as FunnelBarGraphData);
  }
};

const getRowsWithBreakdownInEventCompare = (
  data: any,
  paths: string[][],
  breakdowns: Breakdown[],
  steps: FunnelStep[],
  rows: FunnelDataVizRow[],
  chartData: FunnelBarGraphData[],
  isPastData = false,
): FunnelDataVizRowAndChart => {
  const stepIndexesWithEventCompare = findStepIndexesWithCompare(steps);
  paths.forEach((pathArr) => {
    const rowObj: { [key: string]: any } = {};
    stepIndexesWithEventCompare.forEach((stepIndex, idx) => {
      rowObj[`${stepCompareColumnName(stepIndex)}`] = addPastFlagToCompareString(pathArr[idx], isPastData);
    });
    const nestedData = getNestedData(data, pathArr);
    const brkdwnRows: FunnelDataVizRow[] = getBreakdownRows(0, nestedData, breakdowns, [], []);
    brkdwnRows.forEach((brkdownRow) => {
      const stepEvents = brkdownRow.events as string[];
      const eventsList = stepEvents.map((tempRow, idx) =>
        stepIndexesWithEventCompare.includes(idx) ? compareStepName(idx + 1, steps[idx].compare!.length) : tempRow,
      );
      let obj = { ...brkdownRow };
      const dataRowKey = `${Math.round(Math.random() * 1000000)}`;
      const dataRowColor = getFunnelStepGraphItemColor(rows.length);
      stepEvents.forEach((eventName, eventIndex) => {
        const tempObject = {
          ...((stepIndexesWithEventCompare.includes(+eventIndex)
            ? replacePrefixToKeys(eventName, compareStepName(+eventIndex + 1, steps[+eventIndex].compare!.length), obj)
            : obj) as FunnelDataVizRow),
          key: dataRowKey,
          uniqueKey: dataRowKey,
          color: dataRowColor,
        };
        obj = { ...tempObject };
      });
      const breakdown = (brkdownRow.breakdown as string).split('|');
      addChartDataForEventCompareWithBreakdown(
        breakdown,
        pathArr,
        stepIndexesWithEventCompare,
        steps,
        data,
        chartData,
        dataRowColor,
        dataRowKey,
      );
      rows.push({
        ...obj,
        ...rowObj,
        events: eventsList,
      });
    });
  });
  return { rows, chartData };
};

const getRowsWithoutBreakdownInEventCompare = (
  data: any,
  paths: string[][],
  steps: FunnelStep[],
  rows: FunnelDataVizRow[],
  chartData: FunnelBarGraphData[],
  isPastData = false,
): FunnelDataVizRowAndChart => {
  const stepIndexesWithEventCompare = findStepIndexesWithCompare(steps);
  paths.forEach((pathArr) => {
    const tempRows: FunnelDataVizRow[] = [];
    const currentRowsLength = rows.length;
    const rowObj: { [key: string]: any } = {};
    stepIndexesWithEventCompare.forEach((stepIndex, idx) => {
      rowObj[`${stepCompareColumnName(stepIndex)}`] = addPastFlagToCompareString(pathArr[idx], isPastData);
    });
    const nestedData = getNestedData(data, pathArr);
    Object.keys(nestedData).forEach((property) => {
      const obj = { ...nestedData[property] };
      const row = stepIndexesWithEventCompare.includes(+property)
        ? addPrefixToKeys(compareStepName(+property + 1, steps[+property].compare!.length), obj)
        : addPrefixToKeys(obj.event, obj);
      tempRows.push(roundNumbersInObject(row) as FunnelDataVizRow);
    });
    const eventsList = tempRows.map((tempRow, idx) =>
      stepIndexesWithEventCompare.includes(idx) ? compareStepName(idx + 1, steps[idx].compare!.length) : tempRow.event,
    );
    const dataRowKey = `${Math.round(Math.random() * 1000000)}`;
    const dataRowColor = getFunnelStepGraphItemColor(currentRowsLength);
    addChartDataForEventCompareWithoutBreakdown(
      pathArr,
      stepIndexesWithEventCompare,
      steps,
      data,
      chartData,
      dataRowKey,
      dataRowColor,
    );
    rows.push({
      events: eventsList,
      color: dataRowColor,
      key: dataRowKey,
      uniqueKey: dataRowKey,
      ...flattenArrayOfObjects(tempRows),
      ...rowObj,
      totalConv:
        tempRows.length > 0
          ? (tempRows[tempRows.length - 1][`${eventsList[tempRows.length - 1]}percent_from_first_step`] as number)
          : 0,
    } as FunnelDataVizRow);
  });
  return { rows, chartData };
};

const getRowsWithEventCompare = (
  data: { [key: string]: any },
  steps: FunnelStep[],
  breakdowns: Breakdown[],
  rows: FunnelDataVizRow[],
  chartData: FunnelBarGraphData[],
  isPastData = false,
): FunnelDataVizRowAndChart => {
  const paths = covertFunnelEventsCompareCombinations(data.funnels_events_compare_metadata.funnels_combinations);
  const breakdownColumns = data.funnels_events_compare_metadata.breakdowns;
  if (breakdownColumns && Array.isArray(breakdownColumns) && breakdownColumns.length > 0) {
    return getRowsWithBreakdownInEventCompare(data, paths, breakdowns, steps, rows, chartData, isPastData);
  }
  return getRowsWithoutBreakdownInEventCompare(data, paths, steps, rows, chartData, isPastData);
};

const getRowsWithBreakdown = (
  level: number,
  data: any,
  breakdowns: Breakdown[],
  breakdownPath: string[],
  rows: FunnelDataVizRow[],
  chartData: FunnelBarGraphData[],
  isPastData = false,
): FunnelDataVizRowAndChart => {
  Object.keys(data).forEach((property) => {
    let breakdownVals = [...breakdownPath];
    breakdownVals.push(property);
    if (Array.isArray(data[property])) {
      const dataRowKey = `${Math.round(Math.random() * 1000000)}`;
      const tempRows: FunnelDataVizRow[] = [];
      data[property].forEach((dataRow: { [key: string]: any }) => {
        const obj = { ...dataRow };
        // obj.key = Math.round(Math.random() * 1000000);
        addChartData(true, breakdownVals, obj, chartData, dataRowKey, getFunnelStepGraphItemColor(rows.length));
        const row = addPrefixToKeys(obj.event, obj);
        breakdowns.forEach((brkdwn, idx) => {
          row[brkdwn.property as string] = breakdownVals[idx];
        });
        tempRows.push(roundNumbersInObject(row) as FunnelDataVizRow);
      });
      rows.push({
        ...(flattenArrayOfObjects(tempRows) as FunnelDataVizRow),
        events: tempRows.map((row) => row.event),
        totalConv:
          tempRows.length > 0
            ? (tempRows[tempRows.length - 1][`${tempRows[tempRows.length - 1].event}percent_from_first_step`] as number)
            : 0,
        color: getFunnelStepGraphItemColor(rows.length),
        key: dataRowKey,
        uniqueKey: dataRowKey,
        breakdown: addPastFlagToCompareString(breakdownVals.join('|'), isPastData),
      });
    } else if (typeof data[property] === 'object' && data[property] != null) {
      getRowsWithBreakdown(level + 1, data[property], breakdowns, breakdownVals, rows, chartData);
    }
    breakdownVals = [];
  });
  return { rows, chartData };
};

const getRowsWithoutBreakdown = (
  data: any,
  rows: FunnelDataVizRow[],
  chartData: FunnelBarGraphData[],
  isPastData = false,
): FunnelDataVizRowAndChart => {
  const tempRows: FunnelDataVizRow[] = [];
  const currentRowsLength = rows.length;
  Object.keys(data.funnel).forEach((property) => {
    const obj = { ...data.funnel[property] };
    const row = addPrefixToKeys(obj.event, obj);
    row.breakdown = addPastFlagToCompareString('Overall', isPastData);
    tempRows.push(roundNumbersInObject(row) as FunnelDataVizRow);
  });
  const dataRowKey = `${Math.round(Math.random() * 1000000)}`;
  addChartData(
    false,
    [],
    data.funnel,
    chartData,
    dataRowKey,
    getFunnelStepGraphItemColor(currentRowsLength),
    isPastData,
  );
  rows.push({
    ...(flattenArrayOfObjects(tempRows) as FunnelDataVizRow),
    events: tempRows.map((row) => row.event),
    totalConv:
      tempRows.length > 0
        ? (tempRows[tempRows.length - 1][`${tempRows[tempRows.length - 1].event}percent_from_first_step`] as number)
        : 0,
    color: getFunnelStepGraphItemColor(currentRowsLength),
    key: dataRowKey,
    uniqueKey: dataRowKey,
  });
  return { rows, chartData };
};

const getFormattedData = (
  data: { [key: string]: any },
  steps: FunnelStep[],
  breakdowns: Breakdown[],
  isEventCompareEnabled = false,
  isPastData = false,
): FunnelDataVizRowAndChart => {
  const timeStamps = [...Object.keys(data)];
  if (isEventCompareEnabled && data && data.funnels_events_compare_metadata) {
    const eventCompareRows = getRowsWithEventCompare(data, steps, breakdowns, [], [], isPastData);
    return eventCompareRows;
  }
  if (isBreakdownApplied(breakdowns)) {
    return getRowsWithBreakdown(0, data[timeStamps[0]], breakdowns, [], [], [], isPastData);
  }
  if (!isBreakdownApplied(breakdowns)) {
    return getRowsWithoutBreakdown(data, [], [], isPastData);
  }
  return { rows: [], chartData: [] };
};

const getRowsAndChartsForFunnelSteps = (
  steps: FunnelStep[],
  breakdowns: Breakdown[],
  data: any,
): FunnelDataVizRowAndChart => {
  let res: any = {};
  const isEventCompareEnabled = checkFunnelStepsHasEventCompare(steps);
  const originalData = getFormattedData(data.original, steps, breakdowns, isEventCompareEnabled, false);
  if (
    (data.compare?.funnel && (data.compare?.funnel?.length > 1 || Object.keys(data.compare.funnel).length > 1)) ||
    (isEventCompareEnabled && data.compare && data.compare.length > 1) ||
    Object.keys(data.compare).length > 1
  ) {
    const compareData = getFormattedData(data.compare, steps, breakdowns, isEventCompareEnabled, true);
    res = mergeOriginalAndCompareData(originalData, compareData);
  } else {
    res = originalData;
  }
  return res;
};

const emptyFunnelDataVizRowAndChart = (): FunnelDataVizRowAndChart => ({
  rows: [],
  chartData: [],
});

const getFunnelTrendValue = (funnelTrendType: FunnelTrendType, value: number) => {
  if (funnelTrendType === FunnelTrendType.TIME_TO_CONVERT) {
    return formatTime(value);
  }
  if (funnelTrendType === FunnelTrendType.CONVERSION) {
    return formatPercent(value);
  }
  return value;
};

const getRowsAndChartsWithBreakdownForTrends = (
  data: any,
  breakdowns: string[],
  level: number,
  columnObj: any,
  rows: FunnelDataVizRow[],
  chartData: any[],
  funnelTrendType: FunnelTrendType,
  isPastData: boolean,
): FunnelDataVizRowAndChart => {
  Object.keys(data).forEach((property) => {
    const currentRowsLength = rows.length;
    const keyName = breakdowns[level];
    const obj = {
      ...columnObj,
    };
    if (keyName) {
      obj[keyName] = property;
    }
    const dataKey = Object.keys(data[property])[0];
    if (dataKey && isValidDatetime(dataKey)) {
      const timeSeries = data[property];
      const mergedObj = {
        ...obj,
      };
      const uniqueKey = Object.values(obj).join('/');
      mergedObj.key = addPastFlagToCompareString(uniqueKey, isPastData);
      const dataPoints: any = {};
      Object.keys(timeSeries).forEach((time) => {
        mergedObj[time] = getFunnelTrendValue(funnelTrendType, timeSeries[time][funnelTrendType]);
        dataPoints[time] = timeSeries[time][funnelTrendType];
      });
      mergedObj.color = getGraphItemColor(currentRowsLength);
      rows.push(mergedObj);
      addLineChartData(obj, dataPoints, chartData, isPastData);
    }
    getRowsAndChartsWithBreakdownForTrends(
      data[property],
      breakdowns,
      level + 1,
      obj,
      rows,
      chartData,
      funnelTrendType,
      isPastData,
    );
  });
  return { rows, chartData };
};

const getRowsAndChartsWithoutBreakdownForTrends = (
  data: any,
  rows: FunnelDataVizRow[],
  chartData: any[],
  funnelTrendType: FunnelTrendType,
  isPastData: boolean,
): FunnelDataVizRowAndChart => {
  const currentRowsLength = rows.length;
  const rowObj: any = { key: addPastFlagToCompareString('Overall', isPastData), property: 'Overall' };
  const dataPoints: any = {};

  Object.keys(data).forEach((property) => {
    rowObj[property] = getFunnelTrendValue(funnelTrendType, data[property][funnelTrendType]);
    rowObj.color = getGraphItemColor(currentRowsLength);
    dataPoints[property] = data[property][funnelTrendType];
  });
  rows.push(rowObj);
  addLineChartData({ key: 'Overall' }, dataPoints, chartData, isPastData);
  return { rows, chartData };
};

const getFormattedDataForFunnelTrends = (
  data: any,
  breakdowns: Breakdown[],
  selectedTrendType: FunnelTrendType,
  isPastData: boolean,
): FunnelDataVizRowAndChart => {
  if (isBreakdownApplied(breakdowns)) {
    return getRowsAndChartsWithBreakdownForTrends(
      data.funnel,
      getBreakDownStrArray(breakdowns).flat(),
      0,
      {},
      [],
      [],
      selectedTrendType,
      isPastData,
    );
  }
  if (!isBreakdownApplied(breakdowns)) {
    return getRowsAndChartsWithoutBreakdownForTrends(data.funnel, [], [], selectedTrendType, isPastData);
  }
  return { rows: [], chartData: [] };
};

const getRowsAndChartsForFunnelTrends = (
  breakdowns: Breakdown[],
  data: any,
  selectedTrendType: FunnelTrendType,
  compare: string[] | undefined,
  granularity: GranularityEnum,
  customCompareData: CustomCompareStateType | undefined,
  timestampData: QueryBuilderTimestampInfo | undefined,
): FunnelDataVizRowAndChart => {
  let res: any = {};
  const originalData = getFormattedDataForFunnelTrends(data.original, breakdowns, selectedTrendType, false);
  if (compare && compare.length === 2) {
    const compareFormattedData = getFormattedDataForFunnelTrends(data.compare, breakdowns, selectedTrendType, true);
    if (compareFormattedData.rows.length > 0) {
      const mergedRows = mergeRowsForLineChart(
        originalData.rows,
        compareFormattedData.rows,
        compare,
        customCompareData,
        timestampData,
      );
      const mergedChartData = mergeChartDataForLineChart(
        originalData.chartData as LineChartData[],
        compareFormattedData.chartData as LineChartData[],
      );
      res = { rows: mergedRows, chartData: mergedChartData };
    } else {
      res = originalData;
    }
  } else {
    res = originalData;
  }
  return res;
};

export const generateTableRowsAndChartData = (
  steps: FunnelStep[],
  breakdowns: Breakdown[],
  data: any,
  funnelType: FunnelType,
  selectedTrendType: FunnelTrendType,
  compare: string[] | undefined,
  granularity: GranularityEnum | undefined,
  customCompareData: CustomCompareStateType | undefined,
  timestampData: QueryBuilderTimestampInfo | undefined,
): FunnelDataVizRowAndChart => {
  if (funnelType === FunnelType.STEPS) {
    return getRowsAndChartsForFunnelSteps(steps, breakdowns, data);
  }
  if (funnelType === FunnelType.TRENDS) {
    return getRowsAndChartsForFunnelTrends(
      breakdowns,
      data,
      selectedTrendType,
      compare,
      granularity!,
      customCompareData,
      timestampData,
    );
  }
  return emptyFunnelDataVizRowAndChart();
};

export function insertAggregatesInRowFunnels(
  data: FunnelDataVizRow[],
  compare: string[] | undefined,
  funnelTrendType: FunnelTrendType,
): DataVizRow[] {
  if (funnelTrendType === FunnelTrendType.TOP_OF_FUNNEL || funnelTrendType === FunnelTrendType.BOTTOM_OF_FUNNEL) {
    return insertAverageTotalInRow(data, compare);
  }
  if (funnelTrendType === FunnelTrendType.TIME_TO_CONVERT) {
    return insertAverageTotalInRowWithTime(data, compare);
  }
  if (funnelTrendType === FunnelTrendType.CONVERSION) {
    return insertAverageInRowWithPercentage(data, compare);
  }
  console.error(`Funnel Trend Type ${funnelTrendType} unrecognized.`);
  return [];
}
