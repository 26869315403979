import React from 'react';

import {
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from './DialogPrimitives';

interface DialogProps extends React.PropsWithChildren {
  title?: string;
  description?: string;
  footer?: React.ReactNode;
  open: boolean;
  disableOpenAutoFocus?: boolean;
  onOpenChange?: (open: boolean) => void;
  classNames?: {
    dialogContent?: string;
  };
}

export function Dialog({
  classNames,
  children,
  title,
  description,
  open,
  footer,
  onOpenChange,
  disableOpenAutoFocus = false,
}: DialogProps) {
  return (
    <DialogRoot open={open} onOpenChange={onOpenChange}>
      <DialogTitle />
      <DialogContent
        className={classNames?.dialogContent ?? ''}
        onOpenAutoFocus={disableOpenAutoFocus ? (event) => event.preventDefault() : undefined}
      >
        {title || description ? (
          <DialogHeader>
            {title ? <DialogTitle>{title}</DialogTitle> : null}
            {description ? <DialogDescription>{description}</DialogDescription> : null}
          </DialogHeader>
        ) : null}
        {children}
        {footer ? <DialogFooter>{footer}</DialogFooter> : null}
      </DialogContent>
    </DialogRoot>
  );
}

export function DialogWithIcon({
  classNames,
  children,
  title,
  description,
  open,
  footer,
  onOpenChange,
  disableOpenAutoFocus = false,
  icon,
}: DialogProps & { icon?: React.ReactNode }) {
  return (
    <DialogRoot open={open} onOpenChange={onOpenChange}>
      <DialogTitle />
      <DialogContent
        className={classNames?.dialogContent ?? ''}
        onOpenAutoFocus={disableOpenAutoFocus ? (event) => event.preventDefault() : undefined}
      >
        {title || description ? (
          <DialogHeader className="flex flex-row">
            {icon && (
              <div className="flex items-center space-x-3 mr-4">
                <div className="border border-border p-2 rounded-lg bg-grayBlue-100 dark:bg-grayBlue-600">{icon}</div>
              </div>
            )}
            <div className="flex flex-col">
              {title ? <DialogTitle>{title}</DialogTitle> : null}
              {description ? <DialogDescription>{description}</DialogDescription> : null}
            </div>
          </DialogHeader>
        ) : null}
        {children}
        {footer ? <DialogFooter>{footer}</DialogFooter> : null}
      </DialogContent>
    </DialogRoot>
  );
}
