import { fetchEndpoint, PlatformService } from '@api/utils';

import { UserProfileEndpoints } from './constants';
import {
  FetchUserProfileListRequest,
  FetchUserProfileListResponse,
  GetUserProfileByUniqueIdRequest,
  GetUserProfileByUserIdRequest,
} from './types/request';

export const fetchUserProfilesInWorkspace = async (
  payload: FetchUserProfileListRequest,
): Promise<FetchUserProfileListResponse> => {
  const data: FetchUserProfileListResponse = await fetchEndpoint(
    UserProfileEndpoints.searchUserProfiles,
    {},
    {},
    { ...payload },
    'POST',
    PlatformService,
  );
  return { ...data, traceId: payload.clientTraceId };
};

export const getUserProfileByUserIdInWorkspace = async (payload: GetUserProfileByUserIdRequest) => {
  const data: NonNullable<unknown> = await fetchEndpoint(
    UserProfileEndpoints.getUserProfileByUserId,
    { user_id: payload.userId },
    {},
    {},
    'GET',
    PlatformService,
  );
  return data;
};

export const getUserProfileByUniqueIdInWorkspace = async (payload: GetUserProfileByUniqueIdRequest) => {
  const data: NonNullable<unknown> = await fetchEndpoint(
    UserProfileEndpoints.getUserProfileByUniqueId,
    { unique_id: payload.uniqueId },
    {},
    {},
    'GET',
    PlatformService,
  );
  return data;
};

export const getUserProfilePropertiesInWorkspace = async () => {
  const data: Array<string> = await fetchEndpoint(
    UserProfileEndpoints.getUserProfileProperties,
    {},
    {},
    {},
    'GET',
    PlatformService,
  );
  return data;
};
