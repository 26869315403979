import { isLengthyArray } from '@src/client/lib/utils';
import { SelectOptionsType } from '@src/client/ui-library/select';
import { atom, selector } from 'recoil';

import {
  DataVizRow,
  FunnelBarGraphData,
  FunnelChartData,
  FunnelDataVizRow,
  LineChartData,
} from '../../helpers/reports/types';

export const funnelNameState = atom<string>({
  key: 'funnelNameState',
  default: '',
});

export const funnelDescriptionState = atom<string>({
  key: 'funnelDescriptionState',
  default: '',
});

export const isFunnelsLoadingState = atom<boolean>({
  key: 'isFunnelsLoadingState',
  default: false,
});

export const funnelsRunIdState = atom<string>({
  key: 'funnelsRunIdState',
  default: undefined,
});

export const funnelsErrorState = atom<Error | unknown>({
  key: 'funnelsErrorState',
  default: null,
});

export const funnelReportRowState = atom<FunnelDataVizRow[] | DataVizRow[]>({
  key: 'funnelReportRowState',
  default: [],
});

export const funnelRowsSelectionState = atom<React.Key[]>({
  key: 'funnelRowsSelectionState',
  default: [],
});

export const funnelChartDataState = atom<FunnelChartData>({
  key: 'funnelChartDataState',
  default: [],
});

export const funnelTableDataPageIndexState = atom<number>({
  key: 'funnelTableDataPageIndexState',
  default: 1,
});

export const funnelPrivateDimensionErrorState = atom<Error | unknown>({
  key: 'funnelPrivateDimensionErrorState',
  default: null,
});

export const funnelRawApiResponseState = atom<Record<string, unknown> | null>({
  key: 'funnelRawApiResponseState',
  default: null,
});

export const isFunnelAnyOrderState = atom<boolean>({
  key: 'isFunnelAnyOrderState',
  default: false,
});

export const funnelAggregateTimeOption = atom<SelectOptionsType>({
  key: 'funnelAggregateTimeOption',
  default: {
    value: 'avg',
    label: 'Average',
  },
});

export const filteredFunnelStepChartDataState = selector({
  key: 'filteredFunnelStepChartDataState',
  get: ({ get }) => {
    const chartData = get(funnelChartDataState);
    const selectedRowKeys = get(funnelRowsSelectionState);
    return ((chartData || []) as FunnelBarGraphData[]).filter((d) =>
      isLengthyArray(selectedRowKeys) ? selectedRowKeys.includes(d.dataRowKey) : false,
    );
  },
});

export const filteredFunnelTrendChartDataState = selector({
  key: 'filteredFunnelTrendChartDataState',
  get: ({ get }) => {
    const chartData = get(funnelChartDataState);
    const selectedRowKeys = get(funnelRowsSelectionState);
    return ((chartData || []) as LineChartData[])
      .filter((d) => (isLengthyArray(selectedRowKeys) ? selectedRowKeys.includes(d.key) : false))
      .reduce(
        (accm: LineChartData[], item: LineChartData) =>
          isLengthyArray(item.data)
            ? [
                ...accm,
                {
                  series: item.series,
                  color: item.color,
                  key: item.key,
                  data: [...item.data].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()),
                },
              ]
            : [...accm],
        [],
      );
  },
});

export const isFunnelChartDataEmptySelector = selector({
  key: 'isFunnelChartDataEmptySelector',
  get: ({ get }) => {
    const chartData = get(funnelChartDataState);
    return !isLengthyArray(chartData);
  },
});

export const canLoadMoreFunnelTableDataSelector = selector({
  key: 'canLoadMoreFunnelTableDataSelector',
  get: ({ get }) => {
    const tableRowData = get(funnelReportRowState);
    const tableDataPageIndex = get(funnelTableDataPageIndexState);

    return tableRowData.length > tableDataPageIndex * 100;
  },
});

export const getFunnelTableDataSelector = selector({
  key: 'getFunnelTableDataSelector',
  get: ({ get }) => {
    const tableRowData = get(funnelReportRowState);
    const tableDataPageIndex = get(funnelTableDataPageIndexState);

    return isLengthyArray(tableRowData) ? tableRowData.slice(0, tableDataPageIndex * 100) : [];
  },
});
