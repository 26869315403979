import { Dayjs } from 'dayjs';

export enum ReportType {
  INSIGHT = 'INSIGHT',
  FUNNEL = 'FUNNEL',
  FLOW = 'FLOW',
  RETENTION = 'RETENTION',
}

export enum AlertTypes {
  CUSTOM = 'CUSTOM',
  SMART = 'SMART',
}

export enum AlertsTabEnum {
  AnomaliesDetected = 'Anomalies Detected',
  AlertsList = 'Alerts List',
}

export enum AlertSupportedFrequencies {
  HOURLY = 'HOURLY',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export enum AlertSupportedSeverities {
  INFO = 'INFO',
  WARNING = 'WARNING',
  CRITICAL = 'CRITICAL',
}

export enum AnomalyThresholdType {
  LOWER = 'LOWER',
  UPPER = 'UPPER',
  UPPER_AND_LOWER = 'UPPER_AND_LOWER',
}

export enum AlertSupportedStatuses {
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  INCONSISTENT = 'INCONSISTENT',
  INVALID = 'INVALID',
  DELETED = 'DELETED',
}

export enum AlertRuleTypes {
  ANOMALY = 'ANOMALY',
  THRESHOLD = 'THRESHOLD',
}

export enum AlertSubscriptionTypes {
  EMAIL = 'EMAIL',
  WEBHOOK = 'WEBHOOK',
}

export enum RuleKeyTypes {
  DIMENSION = 'DIMENSION',
  STEP = 'STEP',
  FORMULA = 'FORMULA',
  BREAKDOWN = 'BREAKDOWN',
}

export interface RuleKeyList {
  type: RuleKeyTypes;
  name: string;
  values: { value: string | null; label: string }[];
}

export interface WebhookSubscriptionData {
  webhookUrl: string;
}

export interface EmailSubscriptionData {
  recipients: string[];
}

export interface AlertRuleKey {
  header: string;
  value: string | null | string[];
}

export interface AlertRuleConditions {
  type: AlertRuleTypes;
  op: string;
  threshold?: number;
  confidence?: number;
}

export interface AlertRule {
  k: AlertRuleKey[];
  conditions: AlertRuleConditions;
}

export interface AlertSubscription {
  data: any;
  type: AlertSubscriptionTypes;
}

export interface GetUserAlertRequest {
  limit: number;
  offset: number;
  currentUserOnly: boolean;
}

export interface ReportAlertSummary {
  itemId: string;
  itemExternalId: string;
  configId: string;
  tenantId: string;
  workspaceId: string;
  name: string;
  description: string;
  reportItemExternalId: string;
  reportType: ReportType;
  reportName: string;
  createdBy: string;
  createdAt: number;
  alertStatus: string;
  severity: AlertSupportedSeverities;
}

export interface AlertExtId {
  alertItemExtId: string;
}

export interface AlertItem {
  itemId: string;
  itemExternalId: string;
  configId: string;
  tenantId: string;
  workspaceId: string;
  name: string;
  description: string;
  reportItemExternalId: string;
  startAt?: number;
  createdBy: string;
  createdAt: number;
  alertStatus: string;
  isAnalysisDashboard: boolean;
  dashboardReportList?: string[];
  rules: AlertRule;
  subscriptions: AlertSubscription[];
  frequency: AlertSupportedFrequencies;
  severity: AlertSupportedSeverities;
}

export interface CreateAlertResponse {
  itemExternalId: string;
  configId: string;
  alertStatus: AlertSupportedStatuses;
}

export interface CreateAlertRequest {
  alertType: AlertTypes;
  reportId: string;
  name: string;
  description?: string;
  rules: AlertRule;
  frequency: AlertSupportedFrequencies;
  subscriptions: AlertSubscription[];
  alertStatus: AlertSupportedStatuses;
  alertSeverity: AlertSupportedSeverities;
  startAt: Dayjs | null | undefined;
  isAnalysisDashboard: boolean;
  dashboardReportList?: string[];
}

export interface AlertBuilderData {
  alertType: AlertTypes;
  name: string;
  description?: string;
  rules: AlertRule;
  frequency: AlertSupportedFrequencies;
  subscriptions: AlertSubscription[];
  alertStatus: AlertSupportedStatuses;
  alertSeverity: AlertSupportedSeverities;
  startAt: Dayjs | null | undefined;
  isAnalysisDashboard: boolean;
  dashboardReportList?: string[];
}

export enum ChangeDirection {
  INCREASE = 'INCREASE',
  DECREASE = 'DECREASE',
}
