import {
  customDateRangeState,
  dateRangeState,
} from '@src/client/components/filters-and-selectors/date-range-selector/state';
import { DateRangeEnum, GranularityEnum, ValidDateRangeEnum } from '@src/client/helpers/reports/constants';
import { convertToLabelValue, toTitleCase } from '@src/client/lib/utils';
import { PageType } from '@src/client/routes/types';
import { Select, SelectOptionsType } from '@src/client/ui-library/select';
import { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { atom, useRecoilState, useRecoilValue } from 'recoil';

export const granularityState = atom<GranularityEnum>({
  key: 'granularityStateKey',
  default: GranularityEnum.DAY,
});

export const getGranularityOptionsForCustomDateRange = (
  customDateRange: null | (Dayjs | null)[],
  pageType: PageType,
) => {
  if (!customDateRange || customDateRange.some((date) => date === null)) return [];

  const [fromDate, toDate] = customDateRange;
  if (fromDate === null || toDate === null) return [];
  const diff = toDate.diff(fromDate, 'day');

  if (diff <= 1) {
    return pageType === PageType.RETENTION ? [GranularityEnum.DAY] : [GranularityEnum.HOUR];
  }
  if (diff <= 7) {
    return pageType === PageType.RETENTION ? [GranularityEnum.DAY] : [GranularityEnum.HOUR, GranularityEnum.DAY];
  }
  if (diff <= 30) {
    return [GranularityEnum.DAY, GranularityEnum.WEEK];
  }
  if (diff <= 180) {
    return pageType === PageType.RETENTION
      ? [GranularityEnum.WEEK, GranularityEnum.MONTH]
      : [GranularityEnum.DAY, GranularityEnum.WEEK, GranularityEnum.MONTH];
  }
  return pageType === PageType.RETENTION
    ? [GranularityEnum.MONTH]
    : [GranularityEnum.DAY, GranularityEnum.WEEK, GranularityEnum.MONTH, GranularityEnum.QUARTER];
};

const getGranularityOptionsForFixedDates = (dateRange: DateRangeEnum, pageType: PageType): GranularityEnum[] => {
  const retentionOptions: Record<ValidDateRangeEnum, GranularityEnum[]> = {
    [DateRangeEnum.TODAY]: [GranularityEnum.DAY],
    [DateRangeEnum.YESTERDAY]: [GranularityEnum.DAY],
    [DateRangeEnum.SEVEN_DAYS]: [GranularityEnum.DAY, GranularityEnum.WEEK],
    [DateRangeEnum.FOURTEEN_DAYS]: [GranularityEnum.DAY, GranularityEnum.WEEK],
    [DateRangeEnum.THIRTY_DAYS]: [GranularityEnum.DAY, GranularityEnum.WEEK],
    [DateRangeEnum.THREE_MONTH]: [GranularityEnum.WEEK, GranularityEnum.MONTH],
    [DateRangeEnum.SIX_MONTH]: [GranularityEnum.WEEK, GranularityEnum.MONTH],
    [DateRangeEnum.TWELVE_MONTH]: [GranularityEnum.MONTH],
    [DateRangeEnum.CUSTOM]: [],
    [DateRangeEnum.SINCE]: [],
  };

  const nonRetentionOptions: Record<ValidDateRangeEnum, GranularityEnum[]> = {
    [DateRangeEnum.TODAY]: [GranularityEnum.HOUR],
    [DateRangeEnum.YESTERDAY]: [GranularityEnum.HOUR],
    [DateRangeEnum.SEVEN_DAYS]: [GranularityEnum.HOUR, GranularityEnum.DAY, GranularityEnum.WEEK],
    [DateRangeEnum.FOURTEEN_DAYS]: [GranularityEnum.HOUR, GranularityEnum.DAY, GranularityEnum.WEEK],
    [DateRangeEnum.THIRTY_DAYS]: [GranularityEnum.HOUR, GranularityEnum.DAY, GranularityEnum.WEEK],
    [DateRangeEnum.THREE_MONTH]: [GranularityEnum.DAY, GranularityEnum.WEEK, GranularityEnum.MONTH],
    [DateRangeEnum.SIX_MONTH]: [
      GranularityEnum.DAY,
      GranularityEnum.WEEK,
      GranularityEnum.MONTH,
      GranularityEnum.QUARTER,
    ],
    [DateRangeEnum.TWELVE_MONTH]: [
      GranularityEnum.DAY,
      GranularityEnum.WEEK,
      GranularityEnum.MONTH,
      GranularityEnum.QUARTER,
    ],
    [DateRangeEnum.CUSTOM]: [],
    [DateRangeEnum.SINCE]: [],
  };

  return pageType === PageType.RETENTION
    ? retentionOptions[dateRange as ValidDateRangeEnum]
    : nonRetentionOptions[dateRange as ValidDateRangeEnum];
};

const DEFAULT_OPTIONS = [GranularityEnum.DAY, GranularityEnum.WEEK, GranularityEnum.MONTH];

export default function GranularitySelector({ pageType }: { pageType: PageType }) {
  const customDateRange = useRecoilValue(customDateRangeState);
  const dateRange = useRecoilValue(dateRangeState);
  const [currentGranularity, setCurrentGranularity] = useRecoilState(granularityState);
  const [granularitiesOptions, setGranularitiesOptions] = useState(DEFAULT_OPTIONS);

  const checkCurrentGranularityAndSet = (granularityList: GranularityEnum[]) => {
    if (granularityList.length > 0 && !granularityList.includes(currentGranularity)) {
      setCurrentGranularity(granularityList[0]);
    }
    setGranularitiesOptions(granularityList);
  };

  useEffect(() => {
    if (!dateRange || dateRange === DateRangeEnum.DEFAULT) {
      checkCurrentGranularityAndSet(DEFAULT_OPTIONS);
    } else {
      if (dateRange === DateRangeEnum.CUSTOM || dateRange === DateRangeEnum.SINCE) {
        checkCurrentGranularityAndSet(getGranularityOptionsForCustomDateRange(customDateRange, pageType));
        return;
      }
      checkCurrentGranularityAndSet(getGranularityOptionsForFixedDates(dateRange, pageType));
    }
  }, [dateRange, customDateRange]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Select
      placeholder="Granularity"
      value={convertToLabelValue(currentGranularity)}
      onChange={(value) => setCurrentGranularity((value as SelectOptionsType).value as GranularityEnum)}
      options={granularitiesOptions.map((g) => ({ label: toTitleCase(g), value: g }))}
      selectClassNames={{ control: 'rounded w-[100px]', singleValue: 'font-semibold capitalize text-xs' }}
    />
  );
}
