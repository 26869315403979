import { ChartType } from '@src/client/helpers/reports/constants';
import { convertToLabelValue } from '@src/client/lib/utils';
import {
  BarChartIcon,
  LineChartIcon,
  MetricChartIcon,
  PieChartIcon,
  TableChartIcon,
} from '@src/client/ui-library/icons/ChartIcons';
import { Select, SelectOptionsType } from '@src/client/ui-library/select';
import React from 'react';
import { SingleValueProps } from 'react-select';
import { atom, useRecoilState } from 'recoil';

export const CHART_OPTIONS = [
  { value: ChartType.LINE, label: 'Line Chart', icon: <LineChartIcon /> },
  { value: ChartType.BAR, label: 'Bar Chart', icon: <BarChartIcon /> },
  { value: ChartType.PIE, label: 'Pie Chart', icon: <PieChartIcon /> },
  { value: ChartType.METRIC, label: 'Metric Chart', icon: <MetricChartIcon /> },
  { value: ChartType.TABLE, label: 'Table Chart', icon: <TableChartIcon /> },
];

export const chartTypeState = atom<ChartType>({
  key: 'chartTypeStateKey',
  default: ChartType.LINE,
});

const SingleValue = React.memo(({ children, ...props }: SingleValueProps<unknown>) => {
  const { data } = props;
  const selectedChart = CHART_OPTIONS.find(
    (o) => o.value.toLowerCase() === (data as SelectOptionsType).value.toLowerCase(),
  );
  return (
    <div className=" inline-flex items-center text-xs font-semibold" style={{ gridArea: '1/1/2/3' }}>
      {selectedChart?.icon}
      <span className="ml-1 text-foreground-selected">{selectedChart?.label}</span>
    </div>
  );
});
SingleValue.displayName = 'SingleValue';

export default function ChartTypeSelector() {
  const [chartType, setChartType] = useRecoilState(chartTypeState);
  return (
    <Select
      placeholder="Chart Type"
      value={convertToLabelValue(chartType)}
      options={CHART_OPTIONS}
      onChange={(value) => setChartType((value as SelectOptionsType).value as ChartType)}
      selectClassNames={{ control: 'rounded min-w-[190px]', singleValue: 'font-semibold capitalize' }}
      components={{ SingleValue }}
    />
  );
}
