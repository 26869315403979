import { ConversionWindow } from '@modules/engage/campaign/recoil/atoms';
import { Filter, FormattedFiltersForAPI, LogicalOperatorType } from '@src/client/helpers/reports/types';
import { EngageType } from '@src/client/lib/api/types/response';
import { Dayjs } from 'dayjs';

export enum CampaignStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
}

export enum CommOptionsKeys {
  EMAIL = 'email',
  WHATSAPP = 'whatsapp',
  NOTIFICATION = 'notification',
  SMS = 'sms',
  WEBHOOK = 'webhook',
}
export enum CommOptionsLabels {
  EMAIL = 'Email',
  WHATSAPP = 'WhatsApp',
  NOTIFICATION = 'Push Notification',
  SMS = 'SMS',
  WEBHOOK = 'Webhook',
}

export enum ResumeDndOption {
  SEND = 'SEND',
  IGNORE = 'IGNORE',
  QUEUE = 'QUEUE',
}

export interface CommOptions {
  key: CommOptionsKeys;
  label: CommOptionsLabels;
  value: string;
  imageSrc: string;
}

export interface StatisticsDTO {
  initiated: number;
  sent: number;
  delivered: number;
  viewed: number;
  clicked: number;
  failed: number;
  error: number;
}
export interface CampaignListItemDTO {
  itemId: string;
  configId: string;
  itemExternalId: string;
  itemType: EngageType;
  name: string;
  description: string;
  workspaceId: string;
  status: CampaignStatus;
  createdAt: number;
  createdBy: string;
  successReportExtId: string;
  campaignEndsAt: string | null;
  campaignStartsAt: string | null;
  statistics: StatisticsDTO;
}

export interface DeleteCampaignResponse {
  itemExternalId: string;
  success: boolean;
}

export interface GetCampaignCountRequest {
  currentUserOnly: boolean;
  configStatus: CampaignStatus;
}

export interface GetCampaignsListRequest {
  configStatus: CampaignStatus;
  limit: number;
  offset: number;
  currentUserOnly: boolean;
}

export interface CampaignBuilderCohortParam {
  type: 'COHORT' | 'all';
  property: string | null; // null in case of "all" type
}

export interface SelectedActionDidEvent {
  label: string;
  value: string;
  resource_type: string;
}

export interface SelectedActionDidNotEvent {
  label: string;
  value: string;
  resource_type: string;
}

export interface SelectedReminderTargetEvent {
  label: string;
  value: string;
  resource_type: string;
  filters: Filter[];
}

export interface CampaignBuilderUserDidParams {
  step_label: string;
  event: string;
  resource_type: string;
  filters: Filter[];
  first_time_filter: boolean;
}

export interface CampaignBuilderUserDidParamsAPI {
  step_label: string;
  event: string;
  resource_type: string;
  filters: FormattedFiltersForAPI;
  first_time_filter: boolean;
}

export type CampaignBuilderTimeUnit = 'minutes' | 'hours';

export interface CampaignBuilderUserDidNotParams {
  step_label: string;
  event: string;
  resource_type: string;
  filters: Filter[];
  within: {
    value: number;
    unit: CampaignBuilderTimeUnit;
  };
  first_time_filter: boolean;
}

export interface ReminderTargetEvent {
  step_label: string;
  event: string;
  resource_type: string;
  filters: Filter[];
}

export interface WhastAppCommDataType {
  templateId: string;
  argsPlaceholder?: string[];
}

export interface SMSCommDataType {
  templateId: string;
}

export interface EmailCommDataType {
  templateId: string;
  subject: string;
  body: string;
  params: Map<string, string>;
  replyTo: string[];
  cc: string[];
  bccs: string[];
  attachmentType: EmailAttachmentType | undefined;
  attachmentUrl: string;
  attachments: EmailAttachment[];
}

export interface EmailAttachment {
  fileUrl: string;
  emailAttachmentType: EmailAttachmentType | undefined;
}

export interface NotificationCommDataType {
  title: string;
  message: string;
  image: string | null;
  targetUrl: string;
}
export interface CampaignBuilderCommsConfig {
  webhook: {
    url: string;
  };
  whatsapp: WhastAppCommDataType;
  email: EmailCommDataType;
  notification: NotificationCommDataType;
  sms: SMSCommDataType;
  dnd_resolution_context: {
    action: ResumeDndOption;
  };
}

export interface CampaignLimitsConfigClient {
  maxPerDay: number; // default -> 1
  endsAt: Dayjs | null | undefined;
  startsAt: Dayjs | null | undefined;
}

export interface CampaignLimitsConfigServer {
  maxPerDay: number; // default -> 1
  endsAt: string | null | undefined; // "yyyy-MM-dd HH:mm:ss"
  startsAt: string | null | undefined; // "yyyy-MM-dd HH:mm:ss"
}

export interface CampaignBuilderData {
  itemName: string;
  itemDescription: string;
  itemScope: 'PUBLIC';
  campaignLimits: CampaignLimitsConfigClient;
  successMetric: ReminderTargetEvent;
  params: {
    user_did: Array<CampaignBuilderUserDidParams>;
    user_did_not: CampaignBuilderUserDidNotParams;
    'global-filters': Filter[];
    user_does_expression?: Expression<CampaignBuilderUserDidParams>;
    user_does_conversion_window?: ConversionWindow;
  };
  communicationConfig: CampaignBuilderCommsConfig;
  reminder?: ReminderConfig;
}

export interface CampaignDetailsApiResp {
  itemName: string;
  itemDescription: string;
  itemScope: 'PUBLIC';
  communicationConfig: CampaignBuilderCommsConfig;
  campaignLimits: CampaignLimitsConfigServer;
  successMetric?: {
    step_label: string;
    event: string;
    resource_type: string;
    filters: FormattedFiltersForAPI;
    first_time_filter: boolean;
  };
  params: {
    user_did: Array<{
      step_label: string;
      event: string;
      resource_type: string;
      filters: FormattedFiltersForAPI;
      first_time_filter: boolean;
    }>;
    user_did_not: {
      step_label: string;
      event: string;
      resource_type: string;
      filters: FormattedFiltersForAPI;
      first_time_filter: boolean;
      within: {
        value: number;
        unit: CampaignBuilderTimeUnit;
      };
    } | null;
    'global-filters': FormattedFiltersForAPI;
    user_does_expression?: Expression<CampaignBuilderUserDidParamsAPI>;
    user_does_conversion_window?: ConversionWindow;
  };
  reminder?: {
    targetEvent: { step_label: string; event: string; resource_type: string; filters: FormattedFiltersForAPI };
    configs: ReminderComms[];
  };
}

export interface Expression<T> {
  op?: LogicalOperatorType;
  action?: T;
  subExpressions: Expression<T>[];
}

export interface CampaignCreateApiResp {
  itemId: string;
  itemExternalId: string;
  configId: string;
  workspaceId: string;
  tenantId: string;
}

export interface CampaignTemplateLocale {
  code: string;
  displayName: string;
  translationCode: string | null;
}

export enum CampaignTemplateType {
  WhatsappTemplate = 'WATemplate',
  SmsTemplate = 'SmsTemplate',
  WhatsappTemplateV2 = 'WhatsappTemplateV2',
}

export type CampaignTemplateStatus = 'Pending' | 'Accepted';

export interface CampaignTemplateDescriptor {
  type: CampaignTemplateType;
  message: string;
  parameterCount: number;
  whatsappAttachment: string | null;
  vendorDetails: Record<string, unknown>;
  vendorName: Record<string, unknown>;
  vendorTemplateName: Record<string, unknown>;
  communicationType: string;
}
export interface CampaignTemplateInterface {
  id: string;
  name: string;
  status: CampaignTemplateStatus;
  version: number;
  locale: CampaignTemplateLocale;
  primeTemplate: boolean;
  appNames: string[];
  communicationType: string | null;
  descriptor: CampaignTemplateDescriptor;
  createdBy: string;
  evaluatedBy: string;
  remarks: string | null;
  eventType: string;
  eventConfigNames: string[];
  createdAt: number;
  updatedAt: number;
  type: CampaignTemplateType;
}

export interface CampaignCommunication {
  date: string;
  communicationType: string;
  communicationsSuccess: number;
  communicationsFailed: number;
}

export interface ReminderCommsConfig {
  webhook?: {
    url: string;
  };
  whatsapp?: WhastAppCommDataType;
  email?: EmailCommDataType;
  notification?: NotificationCommDataType;
  sms?: SMSCommDataType;
}

export interface ReminderComms {
  index: number;
  timeInterval: {
    value: number;
    unit: CampaignBuilderTimeUnit;
  };
  communicationConfig: ReminderCommsConfig;
}

export interface ReminderConfig {
  targetEvent: CampaignBuilderUserDidParams;
  configs: ReminderComms[];
}

export enum EmailAttachmentType {
  PDF = 'PDF',
  XLSX = 'XLSX',
  DOCX = 'DOCX',
  JPG = 'JPG',
  JPEG = 'JPEG',
  PNG = 'PNG',
}

export interface NotificationTemplates {
  templateConfigId: string;
  workspaceId: string;
  tenantId: string;
  name: string;
  description: string;
  createdBy: string;
  notificationType: string;
  snapshot: string;
  system?: boolean;
  createdAt: number;
  updatedAt: number;
  template?: string;
}
