import { InsightRunDataResI } from '@src/client/lib/api/types/response';
import { isLengthyArray } from '@src/client/lib/utils';
import { atom, selector, selectorFamily } from 'recoil';

import {
  BreakdownValuesMapFilterObj,
  ChartData,
  DataVizRow,
  InsightBarChartData,
  InsightBarChartSegment,
  LineChartData,
} from '../../helpers/reports/types';

export const insightNameState = atom<string>({
  key: 'insightNameState',
  default: '',
});

export const insightDescriptionState = atom<string>({
  key: 'insightDescriptionState',
  default: '',
});

export const isInsightsLoadingState = atom<boolean>({
  key: 'isInsightsLoadingState',
  default: false,
});

export const insightsRunIdState = atom<string>({
  key: 'insightsRunIdState',
  default: undefined,
});

export const insightsErrorState = atom<Error | unknown>({
  key: 'insightsErrorState',
  default: null,
});

export const insightReportRowState = atom<DataVizRow[]>({
  key: 'insightReportRowState',
  default: [],
});

export const insightRowsSelectionState = atom<React.Key[]>({
  key: 'insightRowsSelectionState',
  default: [],
});

export const insightChartDataState = atom<ChartData>({
  key: 'insightChartDataState',
  default: [],
});

export const insightTableDataPageIndexState = atom<number>({
  key: 'insightTableDataPageIndexState',
  default: 1,
});

export const insightPrivateDimensionErrorState = atom<Error | unknown>({
  key: 'insightPrivateDimensionErrorState',
  default: null,
});

export const insightsRawApiResponseState = atom<InsightRunDataResI | null>({
  key: 'insightsRawApiResponseState',
  default: null,
});

export const isInsightSavedState = atom<boolean>({
  key: 'isInsightSavedState',
  default: true,
});

export const areSelectedRowsDirtyState = atom<boolean>({
  key: 'areSelectedRowsDirtyState',
  default: false,
});

export const insightBreakdownValuesMapState = atom<Record<string, BreakdownValuesMapFilterObj>>({
  key: 'insightBreakdownValuesMapState',
  default: {},
});

export const formattedLineChartDataState = selector({
  key: 'formattedLineChartDataState',
  get: ({ get }) => {
    const chartData = get(insightChartDataState);
    const selectedRowKeys = get(insightRowsSelectionState);
    return ((chartData || []) as LineChartData[])
      .filter((d) => (isLengthyArray(selectedRowKeys) ? selectedRowKeys.includes(d.key) : false))
      .reduce(
        (accm: LineChartData[], item: LineChartData) =>
          isLengthyArray(item.data)
            ? [
                ...accm,
                {
                  series: item.series,
                  color: item.color,
                  key: item.key,
                  data: [...item.data].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()),
                },
              ]
            : [...accm],
        [],
      );
  },
});

export const isInsightChartDataEmptySelector = selector({
  key: 'isInsightChartDataEmptySelector',
  get: ({ get }) => {
    const chartData = get(insightChartDataState);
    return !isLengthyArray(chartData);
  },
});

export const canLoadMoreInsightTableDataSelector = selector({
  key: 'canLoadMoreInsightTableDataSelector',
  get: ({ get }) => {
    const tableRowData = get(insightReportRowState);
    const tableDataPageIndex = get(insightTableDataPageIndexState);

    return tableRowData.length > tableDataPageIndex * 100;
  },
});

export const getInsightTableDataSelector = selector({
  key: 'getInsightTableDataSelector',
  get: ({ get }) => {
    const tableRowData = get(insightReportRowState);
    const tableDataPageIndex = get(insightTableDataPageIndexState);

    return isLengthyArray(tableRowData) ? tableRowData.slice(0, tableDataPageIndex * 100) : [];
  },
});

export const checkIfBarChartDataPresent = selector({
  key: 'checkIfBarChartDataPresent',
  get: ({ get }) => {
    const chartData = get(insightChartDataState);

    if (!isLengthyArray(chartData)) return false;

    const firstEntry = chartData[0];
    return (
      typeof firstEntry === 'object' &&
      firstEntry !== null &&
      'name' in firstEntry &&
      typeof firstEntry.name === 'string' &&
      'total' in firstEntry &&
      typeof firstEntry.total === 'number'
    );
  },
});

export const getBarChartMaxSegmentValue = selector({
  key: 'getBarChartMaxSegmentValue',
  get: ({ get }) => {
    const barChartData = get(insightChartDataState) as InsightBarChartData;
    const isValidBarChartData = get(checkIfBarChartDataPresent);

    if (!isLengthyArray(barChartData) || !isValidBarChartData) return 0;

    let maxLeafTotal = -Infinity;

    function traverse(segment: InsightBarChartSegment) {
      // Check if the current node is a leaf (no children property)
      if (!isLengthyArray(segment.children)) {
        maxLeafTotal = Math.max(maxLeafTotal, segment.total);
      } else {
        // If the node has children, recursively traverse each child
        segment.children!.forEach((child) => traverse(child));
      }
    }

    // Traverse each root node in the top-level array
    barChartData.forEach((segment) => traverse(segment));

    return maxLeafTotal;
  },
});

export const getInsightBreakdownValuesFilterArr = selector({
  key: 'getInsightBreakdownValuesFilterArr',
  get: ({ get }) => {
    const breakdownValuesMapping = get(insightBreakdownValuesMapState);

    return Object.values(breakdownValuesMapping).sort((a, b) => b.index - a.index);
  },
});

export const isBarGraphItemSelectedState = selectorFamily({
  key: 'isBarGraphItemSelectedState',
  get:
    ({ breakdownKey, breakdownValue }: { breakdownKey: string; breakdownValue: string }) =>
    ({ get }) => {
      const breakdownValuesMapping = get(insightBreakdownValuesMapState);
      if (breakdownValuesMapping[breakdownKey]) {
        return (
          breakdownValuesMapping[breakdownKey].values.findIndex(
            (item) => item.value === breakdownValue && item.selected,
          ) > -1
        );
      }

      return false;
    },
});
