import { SelectOptionsType } from '@src/client/ui-library/select';

export const FunnelAggregateAverageTimeOption = {
  value: 'avg',
  label: 'Average',
};

export const FunnelAggregateTimeOptions: SelectOptionsType[] = [
  FunnelAggregateAverageTimeOption,
  {
    value: '50',
    label: 'Median',
  },
  {
    value: '25',
    label: '25th Percentile',
  },
  {
    value: '75',
    label: '75th Percentile',
  },
  {
    value: '90',
    label: '90th Percentile',
  },
  {
    value: '95',
    label: '95th Percentile',
  },
  {
    value: '99',
    label: '99th Percentile',
  },
];
